import { updateUser } from "functions";
import { useEffect } from "react";

export default function useLoginByBearer(bearer) {
	useEffect(() => {
		if (bearer) {
			localStorage.setItem("aToken", JSON.stringify(bearer));
			updateUser();
		}
	}, [bearer]);
}

import axios from "axios";
import i18n from "i18n";
import logoutUser from "functions/logout-user";
import displayError from "./displayError";
let ServerKey = "ServerKey QM7d3gb7vZBateWGzNtZhwVR2BgedOzPyR0I9KBw_3KfdT9c7";

const RequestedDomain = process.env.REACT_APP_API_DOMAIN;

function getAtoken() {
	let aToken = "Bearer " + JSON.parse(localStorage.getItem("aToken"));
	return aToken;
}

function storeAToken(response) {
	try {
		localStorage.setItem(
			"aToken",
			JSON.stringify(response.headers.authorization.split(" ")[1])
		);
	} catch (error) {
		// console.log(error);
	}
}

function handleError(error, config) {
	if (
		error?.response?.data &&
		(error.response.data.status === 403 || error.response.data.error === 1001)
	) {
		logoutUser();
	} else if (error?.response?.data || config?.showDefaultError) {
		displayError(error?.response?.data, config);
	}
}

function parsePath(path) {
	path = path.replace("$lang", i18n.languages[0]);
	return path;
}

let API = {
	post: async (path, payload = {}, config = { showDefaultError: false }) => {
		path = parsePath(path);
		const response = await axios({
			method: "post",
			headers: {
				Authorization: getAtoken(),
				"Content-Type": "application/json",
			},
			url: `${RequestedDomain}/${path}`,
			data: payload,
		}).catch((error) => {
			handleError(error, config);
			throw error;
		});
		storeAToken(response);
		return response;
	},

	postSK: async (path, payload = {}, config = { storeBearer: true }) => {
		path = parsePath(path);
		const response = await axios({
			method: "post",
			headers: {
				Authorization: ServerKey,
				"Content-Type": "application/json",
			},
			url: `${RequestedDomain}/${path}`,
			data: payload,
		}).catch((error) => {
			handleError(error);
			throw error;
		});
		if (config.storeBearer) storeAToken(response);
		return response;
	},

	get: async (path) => {
		path = parsePath(path);
		const response = await axios({
			method: "get",
			headers: {
				Authorization: getAtoken(),
				"Content-Type": "application/json",
			},
			url: `${RequestedDomain}/${path}`,
		}).catch((error) => {
			handleError(error);
			throw error;
		});
		storeAToken(response);
		return response;
	},

	multipart: async (path, payload = {}, uploadPercent) => {
		path = parsePath(path);
		const response = await axios({
			method: "post",
			headers: {
				Authorization: getAtoken(),
				"Content-Type": "multipart/form-data",
			},
			url: `${RequestedDomain}/${path}`,
			data: payload,
			onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total
				);
				uploadPercent(percentCompleted);
			},
		}).catch((error) => {
			handleError(error);
			throw error;
		});
		storeAToken(response);
		return response;
	},

	delete: async (path, payload = {}) => {
		path = parsePath(path);
		const response = await axios({
			method: "delete",
			headers: { Authorization: getAtoken() },
			url: `${RequestedDomain}/${path}`,
			data: payload,
		}).catch((error) => {
			handleError(error);
			throw error;
		});
		return response;
	},

	// to do: add general req method
};

const specificAPI = {
	get_user_country: async (ip = null) => {
		const response = await axios({
			method: "get",
			url: `https://ip2c.org/${ip || "s"}`,
		}).catch(function (error) {
			throw error;
		});
		return response;
	},

	get_vimeo_thumbnail: async (url) => {
		const response = await axios({
			method: "get",
			url: `https://vimeo.com/api/oembed.json?url=${url}`,
		}).catch(function (error) {
			throw error;
		});
		return response;
	},

	loginwithapple: `${RequestedDomain?.split("/api/v2")[0]}/loginwithapple`,
};

API = Object.assign(API, specificAPI);

export { API };

// depracated api
const APIrequest = {
	get_url_token: function (url) {
		let splitedURLarray = url.split("/");
		return splitedURLarray[splitedURLarray.length - 1];
	},

	login_get_password: async function (data) {
		const response = await axios({
			method: "post",
			headers: { Authorization: ServerKey },
			url: RequestedDomain + "/login-get-password/",
			data: data,
		});
		return response;
	},

	login_with_social: async function (data, social) {
		const response = await axios({
			method: "post",
			headers: { Authorization: ServerKey },
			url: RequestedDomain + "/login-with-social/" + social,
			data: data,
		});
		storeAToken(response);
		return response;
	},

	login_with_password: async function (data) {
		const response = await axios({
			method: "post",
			headers: { Authorization: ServerKey },
			url: RequestedDomain + "/login-with-password",
			data: data,
		});
		storeAToken(response);
		return response;
	},

	login_with_pin: async function (data) {
		const response = await axios({
			method: "post",
			headers: { Authorization: ServerKey },
			url: RequestedDomain + "/login-with-pin",
			data: data,
		});
		storeAToken(response);
		return response;
	},

	reset_password: async function (data, auth) {
		const response = await axios({
			method: "post",
			headers: { Authorization: auth ? auth : getAtoken() },
			url: RequestedDomain + "/reset-password",
			data: data,
		});
		storeAToken(response);
		return response;
	},

	get_tags_by_categories: async function (data, locale) {
		const response = await axios({
			method: "post",
			headers: {
				Authorization: getAtoken(),
				"Content-Type": "application/json",
			},
			url: RequestedDomain + "/get-tags-by-categories/" + locale,
			data: data,
		});
		return response;
	},

	get_tags_by_ids: async function (data, locale) {
		const response = await axios({
			method: "post",
			headers: {
				Authorization: getAtoken(),
				"Content-Type": "application/json",
			},
			url: RequestedDomain + "/get-tags-by-ids/" + locale,
			data: data,
		});
		return response;
	},

	register_user: async function (data) {
		const response = await axios({
			method: "post",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/register-user/",
			data: data,
		}).catch(function (error) {
			if (error.response.data.status === 403) {
				logoutUser();
			}
		});
		return response;
	},

	get_user_full: async function () {
		const response = await axios({
			method: "get",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/get-user-full/",
		}).catch((error) => {
			logoutUser();
			return error.response;
		});
		if (response.data.status === "DEACTIVATED") logoutUser();
		return response;
	},

	get_insta_user: async function (token) {
		const response = await axios({
			method: "get",
			url: "https://api.instagram.com/v1/users/self/?access_token=" + token,
		}).catch(function (error) {
			console.log(response);
		});
		return response;
	},

	get_youtube_user: async function (token) {
		const response = await axios({
			method: "get",
			url:
				"https://www.googleapis.com/youtube/v3/channels?access_token=" +
				token +
				"&part=snippet&mine=true",
		}).catch(function (error) {
			console.log(response);
		});
		return response;
	},

	get_categories: async function (locale, full) {
		const response = await axios({
			method: "get",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/get-categories/" + locale + "/" + full,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	get_categories_by_names: async function (data, locale) {
		const response = await axios({
			method: "post",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/get-categories-by-names/" + locale,
			data: data,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	get_langs: async function () {
		const response = await axios({
			method: "get",
			url: "/json/languages.json",
		});
		return response;
	},

	apply_promo_code: async function (code) {
		const response = await axios({
			method: "get",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/apply-promo-code/" + code,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	verify_promo_code: async function (data) {
		const response = await axios({
			method: "post",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/verify-promo-code",
			data: data,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	get_scores: async function (idUser) {
		const response = await axios({
			method: "get",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/get-scores/" + idUser,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	get_complete_products: async function (data, idUser) {
		const response = await axios({
			method: "post",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/get-complete-products-short/" + idUser,
			data: data,
		}).catch((error) => {
			throw error;
		});
		return response;
	},

	find_users_by_name: async function (data) {
		const response = await axios({
			method: "post",
			headers: {
				Authorization: getAtoken(),
				"Content-Type": "application/json",
			},
			url: RequestedDomain + "/find-users-by-name/true",
			data: data,
		}).catch((error) => {
			throw error;
			// handleError(error);
			// return error.response;
		});
		return response;
	},

	find_users: async function (data) {
		const response = await axios({
			method: "post",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/find-users",
			data: data,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	get_users_by_categories: async function (data) {
		const response = await axios({
			method: "post",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/get-users-by-categories",
			data: data,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	get_placeholders: async function (data, locale) {
		const response = await axios({
			method: "post",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/get-placeholders/" + locale,
			data: data,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	get_users_by_ids: async function (data) {
		const response = await axios({
			method: "post",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/get-users-by-ids",
			data: data,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	socket_auth: async function () {
		const response = await axios({
			method: "get",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/socket-auth",
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	get_cities: async function (locale) {
		const response = await axios({
			method: "get",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + `/get-cities/${locale}`,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	cancel_product: async function (idTransaction) {
		const response = await axios({
			method: "get",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + `/cancel-product/${idTransaction}`,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	get_warehouses_by_city: async function (ref, locale) {
		const response = await axios({
			method: "get",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + `/get-warehouses-by-city/${ref}/${locale}`,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	change_password: async function (data) {
		const response = await axios({
			method: "post",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/change-password",
			data: data,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	user_list: async function (data) {
		const response = await axios({
			method: "post",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/user-list",
			data: data,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	add_device: async function (data) {
		const response = await axios({
			method: "post",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/add-device/",
			data: data,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	get_twilio_tokens: async function (idPartner) {
		const response = await axios({
			method: "get",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/get-twilio-tokens/" + idPartner,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	transaction_log: async function (data) {
		const response = await axios({
			method: "post",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/transaction-log",
			data: data,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	call_status: async function (status, callerId, twilioToken) {
		let twilioTokenURL = "Bearer " + twilioToken;
		const response = await axios({
			method: "post",
			headers: { Authorization: twilioTokenURL },
			url: RequestedDomain + "/call-status/" + status + "/" + callerId,
		});
		return response;
	},

	get_user_by_id: async function (idUser) {
		const response = await axios({
			method: "get",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/get-user-by-id/" + idUser,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	get_active_user_products: async function (idUser) {
		const response = await axios({
			method: "get",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/get-active-user-products/" + idUser,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	get_user_products: async function () {
		const response = await axios({
			method: "get",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/get-user-products",
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	get_product_by_id: async function (id) {
		const response = await axios({
			method: "get",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/get-product-by-id/" + id,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	get_main_screen_data: async function (locale) {
		const response = await axios({
			method: "get",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/get-main-screen-data-web/" + locale,
		}).catch((error) => {
			handleError(error);
			throw error;
			// return error.response;
		});
		return response;
	},

	get_groups: async function (locale) {
		const response = await axios({
			method: "get",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/get-groups/" + locale + "/false",
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	find_users_by_group: async function (data) {
		const response = await axios({
			method: "post",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/find-users-by-group/",
			data: data,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	buy_product: async function (data) {
		const response = await axios({
			method: "post",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/buy-product/",
			data: data,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	save_product: async function (data) {
		const response = await axios({
			method: "post",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/save-product/",
			data: data,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	subscribe: async function (data) {
		const response = await axios({
			method: "post",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/subscribe",
			data: data,
		}).catch((error) => {
			throw error;
		});
		return response;
	},

	confirm_document: async function (idUser) {
		const response = await axios({
			method: "get",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/confirm-document",
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	history_log_by_id: async function (productId) {
		const response = await axios({
			method: "get",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/history-log-by-id/" + productId,
		}).catch((error) => {
			handleError(error);
			throw error;
			// return error.response;
		});
		return response;
	},

	unsubscribe_from_notifications: async function (unsubscribeToken) {
		let token = "Bearer " + unsubscribeToken;
		const response = await axios({
			method: "post",
			headers: {
				Authorization: token,
				"Content-Type": "application/json",
			},
			data: {},
			url: RequestedDomain + "/unsubscribe-email-notifications/",
		}).catch(function (error) {
			console.log(error);
		});
		return response;
	},

	update_email: async function (operToken, data) {
		let token = operToken ? "Bearer " + operToken : getAtoken();
		const response = await axios({
			method: "post",
			headers: {
				Authorization: token,
				"Content-Type": "application/json",
			},
			data: data,
			url: RequestedDomain + "/update-email",
		}).catch(function (error) {
			console.log(error);
		});
		return response;
	},

	update_user: async function (data) {
		const response = await axios({
			method: "post",
			headers: {
				Authorization: getAtoken(),
				"Content-Type": "application/json",
			},
			data: data,
			url: RequestedDomain + "/update-user",
		}).catch(function (error) {
			throw error;
		});
		return response;
	},

	link_social: async function (data, social) {
		const response = await axios({
			method: "post",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/link-social/" + social,
			data: data,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		storeAToken(response);
		return response;
	},

	delete_social: async function (social) {
		const response = await axios({
			method: "delete",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/delete-social/" + social,
		}).catch(function (error) {
			console.log(error);
		});
		return response;
	},

	delete_user: async function (social) {
		const response = await axios({
			method: "delete",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/delete-user",
		}).catch(function (error) {
			console.log(error);
		});
		return response;
	},

	delete_message: async function (idMessage) {
		const response = await axios({
			method: "delete",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/delete-message/" + idMessage,
		}).catch(function (error) {
			console.log(error);
		});
		return response;
	},

	remove_device: async function (data) {
		const response = await axios({
			method: "delete",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/remove-devices",
			data: data,
		}).catch(function (error) {
			console.log(error);
		});
		return response;
	},

	clear_user_fields: async function (data) {
		const response = await axios({
			method: "delete",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/clear-user-fields",
			data: data,
		}).catch(function (error) {
			console.log(error);
		});
		return response;
	},

	users_history: async function (data) {
		const response = await axios({
			method: "post",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/users-history",
			data: data,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		storeAToken(response);
		return response;
	},

	history_log: async function (data, idPartner, updateSeen) {
		const response = await axios({
			method: "post",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/history-log/" + idPartner + "/" + updateSeen,
			data: data,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		storeAToken(response);
		return response;
	},

	send_message: async function (data) {
		const response = await axios({
			method: "post",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/send-message",
			data: data,
		}).catch((error) => {
			handleError(error);
			throw error;
			// return error.response
		});
		storeAToken(response);
		return response;
	},

	complain: async function (data) {
		const response = await axios({
			method: "post",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/complain",
			data: data,
		}).catch((error) => {
			handleError(error);
			throw error;
		});
		return response;
	},

	answer_message: async function (data) {
		const response = await axios({
			method: "post",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/answer-message",
			data: data,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		storeAToken(response);
		return response;
	},

	verify_corporate_email: async function (operToken, data) {
		let token = getAtoken();
		if (operToken) token = "Bearer " + operToken;
		let response = await axios({
			method: "post",
			headers: {
				Authorization: token,
				"Content-Type": "application/json",
			},
			data: data,
			url: RequestedDomain + "/verify-corporate-email",
		});
		return response;
	},

	cancel_message: async function (idMessage) {
		const response = await axios({
			method: "get",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/cancel-message/" + idMessage,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		storeAToken(response);
		return response;
	},

	get_charity_funds: async function (locale) {
		const response = await axios({
			method: "get",
			headers: { Authorization: getAtoken() },
			url: RequestedDomain + "/get-charity-funds/" + locale,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	remind_after: async function (data) {
		const response = await axios({
			method: "post",
			headers: {
				Authorization: getAtoken(),
				"Content-Type": "application/json",
			},
			data: data,
			url: RequestedDomain + "/remind-after",
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		return response;
	},

	upgrade_expert: async function (data) {
		const response = await axios({
			method: "post",
			headers: {
				Authorization: getAtoken(),
				"Content-Type": "application/json",
			},
			data: data,
			url: RequestedDomain + "/upgrade-expert",
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		storeAToken(response);
		return response;
	},

	send_withdraw: async function (data) {
		let response = await axios({
			method: "post",
			headers: {
				Authorization: getAtoken(),
				"Content-Type": "application/json",
			},
			url: RequestedDomain + "/send-withdraw",
			data: data,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		storeAToken(response);
		return response;
	},

	set_score: async function (data) {
		let response = await axios({
			method: "post",
			headers: {
				Authorization: getAtoken(),
				"Content-Type": "application/json",
			},
			url: RequestedDomain + "/set-score",
			data: data,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		storeAToken(response);
		return response;
	},

	upload_image: async function (data) {
		let response = await axios({
			method: "post",
			headers: {
				Authorization: getAtoken(),
				"content-type": "multipart/form-data",
			},
			url: RequestedDomain + "/upload-image",
			data: data,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		storeAToken(response);
		return response;
	},

	upload_some_image: async function (data) {
		let response = await axios({
			method: "post",
			headers: {
				Authorization: getAtoken(),
				"content-type": "multipart/form-data",
			},
			url: RequestedDomain + "/upload-some-image",
			data: data,
		}).catch((error) => {
			handleError(error);
			return error.response;
		});
		storeAToken(response);
		return response;
	},

	fondy_deposit: async function (data) {
		const response = await axios({
			method: "post",
			headers: { "Content-Type": "application/json" },
			url: "https://api.fondy.eu/api/checkout/redirect/",
			data: data,
		});
		return response;
	},
};

export { storeAToken };
export default APIrequest;

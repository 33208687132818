import { store } from "store";

export default function currencyRate(price, config = {}) {
	const { hideDefaultCurrency, mask } = config;
	const defaultCurrency = "USD";
	const {
		currencies: {
			selectedCurrency: { rate, symbol, name },
		},
	} = store.getState();
	const fixedValue = name === "USD" ? 2 : 0;
	const amount = Number((price * rate).toFixed(fixedValue));

	if (hideDefaultCurrency && name === defaultCurrency) {
		return null;
	}

	const priceLabel = `${symbol}${amount}`;

	return !!mask ? mask({ priceLabel, symbol, amount }) : priceLabel;
}

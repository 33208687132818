import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./css/auction-winner-modal.module.css";

// libs
import { useTranslation } from "react-i18next";

// components
import Modal from "./Modal";
import { API } from "functions";

export default function AuctionWinnerUnpaidModal(props) {
	const {
		product: { idProduct, productName, idOwner } = {},
		isOpen,
		closeModal,
	} = props;
	const [idSupport, setIdSupport] = useState(null);
	const { t, i18n } = useTranslation();
	const history = useHistory();

	useEffect(() => {
		getSupportUser();
	}, []);

	const getSupportUser = async () => {
		try {
			const locale = i18n.languages[0];
			const {
				data: { idChat },
			} = await API.get(`get-chat-with-support/${locale}`);

			setIdSupport(idChat);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			closeModal={closeModal}
			noPadding
			maxWidth="400px"
			borderRadius="20px"
			ignoreOnClose
		>
			<div styleName="container">
				<div styleName="content">
					<div className="title-5 center mb_24">{t("OneStep.Title")}</div>
					<div className="text-1 c_base60 center mb_24">
						{t("UnableToChargeTheAmount.Text", {
							productName: productName,
						})}
					</div>
					{idSupport && (
						<button
							className="pr-btn_gray fw mb_16"
							onClick={() => history.push(`/chat/${idSupport}`)}
						>
							{t("ContactSupport.Button")}
						</button>
					)}
					<button
						className="pr-btn_prim fw"
						onClick={() =>
							history.push(
								`/auction-winner?product=${idProduct}&owner=${idOwner}`
							)
						}
					>
						{t("GoToPayment.Button")}
					</button>
				</div>
			</div>
		</Modal>
	);
}

import { API } from "functions";

export async function checkCountry(callback) {
	const storedCountry = localStorage.getItem("country");
	if (!storedCountry) {
		const country = await getCountryByIp();
		if (!!country) {
			localStorage.setItem("country", country);
			callback(country);
		}
	} else {
		callback(storedCountry);
	}
}

export async function getCountryByIp() {
	try {
		const response = await API.get_user_country();
		return response.data.split(";")[1].toLowerCase();
	} catch (error) {
		return null;
	}
}

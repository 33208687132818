import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "./css/donate-modal.module.css";

// redux
import { useSelector } from "react-redux";

// utils
import { API } from "functions";

// libs
import { useTranslation } from "react-i18next";

// components
import CheckoutRedirect from "components/common/stripe/checkout redirect";
import DonateVariationModal from "./DonateVariationModal";
import DonateTypeModal from "./DonateTypeModal";
import LoginModal from "./LoginModal";

export default function DonateModal(props) {
	const { idUser, isOpen, onClose } = props;
	const { i18n } = useTranslation();
	const history = useHistory();
	const [donation, setDonation] = useState(null);
	const [activeVariation, setActiveVariation] = useState(null);
	const [inputSum, setInputSum] = useState("");
	const [comment, setComment] = useState("");
	const [sessionId, setSessionId] = useState(null);
	const [activeScreen, setActiveScreen] = useState("variations");
	const [isRegular, setIsRegular] = useState(false);
	const [email, setEmail] = useState("");
	const [emailIV, setEmailIV] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [phoneNumberIV, setPhoneNumberIV] = useState(false);
	const { user } = useSelector((state) => state.user);

	const changeActiveVariation = (id) => setActiveVariation(id);
	const onCommentChange = (text) => setComment(text);
	const onInputSumChange = (sum) => setInputSum(sum);
	const onScreenChange = (screen) => setActiveScreen(screen);
	const onTypeChange = (regular) => setIsRegular(regular);
	const getSelectedPrice = () =>
		activeVariation === "manual"
			? inputSum
			: donation.variations.find(
					(variation) => variation.id === activeVariation
			  )?.price;

	useEffect(() => {
		getAvailableDonations();
	}, []);

	const onTypePicked = () => {
		const isLoggenIn = !!user;
		isLoggenIn ? makeDonation() : onScreenChange("login");
	};

	const getAvailableDonations = async () => {
		try {
			const { data: donat } = await API.get(
				`get-available-donations/${idUser}`
			);
			setDonation(donat);
			setActiveVariation(donat.variations[0].id);
		} catch (error) {
			console.log(error);
		}
	};

	const makeDonation = async () => {
		try {
			const { idProduct } = donation;
			const data = {
				idProduct: idProduct,
				text: comment,
				language: i18n.languages[0],
				successURL: `${window.origin}/product-bought?pt=donation`,
				cancelURL: window.location.href,
				regular: isRegular,
			};
			activeVariation === "manual"
				? (data.customPrice = inputSum)
				: (data.variationId = activeVariation);
			if (phoneNumberIV) data.phoneNumber = phoneNumber;
			if (emailIV) data.email = email;
			const {
				data: { result },
			} = await API.post("donate", data);
			if (result === "success") history.push("/product-bought?pt=donation");
			else {
				const url = new URL(result);
				const query = new URLSearchParams(url.search);
				const sessionId = query.get("si");
				setSessionId(sessionId);
			}
		} catch (error) {
			console.log(error);
		}
	};

	if (!isOpen) return null;
	if (!donation) return null;
	switch (activeScreen) {
		case "variations":
			return (
				<DonateVariationModal
					isOpen={isOpen}
					inputSum={inputSum}
					donation={donation}
					activeVariation={activeVariation}
					onClose={onClose}
					onSubmit={onScreenChange}
					changeActiveVariation={changeActiveVariation}
					onInputSumChange={onInputSumChange}
				/>
			);
		case "type":
			return (
				<>
					<DonateTypeModal
						isOpen={isOpen}
						onClose={onClose}
						price={getSelectedPrice()}
						isRegular={isRegular}
						onTypeChange={onTypeChange}
						onCommentChange={onCommentChange}
						onSubmit={onTypePicked}
						onBackBtnClick={() => onScreenChange("variations")}
					/>
					<CheckoutRedirect active={sessionId} sessionId={sessionId} />
				</>
			);
		case "login":
			return (
				<>
					<LoginModal
						isOpen={isOpen}
						emailIV={emailIV}
						email={email}
						phoneNumber={phoneNumber}
						onClose={onClose}
						onSubmit={makeDonation}
						onBackBtnClick={() => onScreenChange("type")}
						onEmailChange={({ email, emailIV }) => {
							setEmail(email);
							setEmailIV(emailIV);
						}}
						onPhoneChange={({ phoneNumber, phoneNumberIV }) => {
							setPhoneNumber(phoneNumber);
							setPhoneNumberIV(phoneNumberIV);
						}}
					/>
					<CheckoutRedirect active={sessionId} sessionId={sessionId} />
				</>
			);
		default:
			return null;
	}
}

import { CALL_STATUS } from '../constants/list';

const initialState = {
  callStatus: {
    isCalling: false,
    parnterEndCall: false,
    status: ''
  }
}

export default function callStatus(state = initialState, action) {
  switch (action.type) {
    case CALL_STATUS:
      return { ...state, callStatus: action.payload }

    default:
      return state;
  }
}


import React from "react";
import "./css/donate-modal.module.css";

// libs
import { useTranslation } from "react-i18next";

// components
import CommentInput from "./CommentInput";
import Modal from "components/common/modal/Modal";
import DonationTypes from "./DonationTypes";

export default function DonateTypeModal(props) {
	const {
		isOpen,
		comment,
		price,
		isRegular,
		onTypeChange,
		onCommentChange,
		onClose,
		onSubmit,
		onBackBtnClick,
	} = props;
	const { t } = useTranslation();
	const backBtnHandle = () => onBackBtnClick("variations");

	return (
		<Modal
			isOpen={isOpen}
			closeModal={onClose}
			noPadding
			maxWidth="400px"
			borderRadius="16px"
		>
			<div styleName="donate-modal" className="scrollable-content">
				<div styleName="donate-modal-content">
					<button className="back-btn" onClick={backBtnHandle} />
					<div className="title-5">{t("Common.SelectType")}</div>
					<DonationTypes
						price={price}
						activeType={isRegular}
						onTypeChange={onTypeChange}
					/>
					<CommentInput text={comment} onChange={onCommentChange} />
					<button className="pr-btn_prim fw mb_16" onClick={onSubmit}>
						{t("Common.Next")}
					</button>
				</div>
			</div>
		</Modal>
	);
}

import React, { Component } from 'react';
import './css/modal.module.css';

export default class ModalContent extends Component {
	render() {
		const { texts } = this.props;

		return (
			<div styleName="modal-content">
				{texts.map((text, index) => 
                    <div key={index} className="text-1 c_base60">{text}</div>
                )}
			</div>
		);
	}
}
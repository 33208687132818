import { SET_PIN_REDIRECT } from '../constants/list';

const initialState = {
    setPinRedirect: null
}

export default function setPinRedirect(state = initialState, action) {
    switch (action.type) {
        case SET_PIN_REDIRECT:
            return { ...state, setPinRedirect: action.payload }

        default:
            return state;
    }
}


import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Modal from "./Modal";

export default function NeedToLoginModal(props) {
	const { isOpen, closeModal } = props;
	const { t } = useTranslation();
	const history = useHistory();

	return (
		<Modal
			isOpen={isOpen}
			closeModal={closeModal}
			title={t("Profile.LoginModal.Title.Web")}
			texts={[t("Profile.LoginModal.Text.Web")]}
			childdrenStyleName="modal-btns"
		>
			<button className="ppp-btn ppp-btn_transparent" onClick={closeModal}>
				{t("Common.Cancel")}
			</button>
			<button className="ppp-btn" onClick={() => history.push("/login")}>
				{t("Auth.LogIn.Button")}
			</button>
		</Modal>
	);
}

import { Client } from "@stomp/stompjs";

// todo: rewrite to class, need more flexibility to use delta and unsubscription
export default function stompConnection(params, callbacks = {}) {
	const { headers, link, sync = false, useDelta = false } = params;
	const { newMessage, onSubscribe = () => {} } = callbacks;
	const syncStep = 180000;
	let refreshIntervalId;
	let lastReceivedDate = null;

	const client = new Client({
		brokerURL: process.env.REACT_APP_WS_URL,
		connectHeaders: {
			login: "web_socket",
			passcode: process.env.REACT_APP_WS_PASSCODE,
		},
		debug: function (str) {
			// console.log(str);
		},
		reconnectDelay: 5000,
		heartbeatIncoming: 30000,
		heartbeatOutgoing: 30000,
	});

	client.onConnect = (frame) => {
		const callback = (message) => {
			const recivedBody = JSON.parse(message.body);
			updateDelta(recivedBody);
			if (message.body) {
				// console.log(message.body);
				newMessage(message);
			} else {
				// console.log("got empty message");
			}
		};

		const syncWS = () => {
			try {
				const syncConfig = {
					destination: "/sync",
					headers: headers,
				};
				if (lastReceivedDate)
					syncConfig.body = JSON.stringify({
						lastReceivedDate: lastReceivedDate,
					});
				client.publish(syncConfig);
			} catch (error) {
				// console.log(error);
			}
		};

		if (sync) {
			syncWS();
			refreshIntervalId = setInterval(() => {
				syncWS();
			}, syncStep);
		}
		// Do something, all subscribes must be done is this callback
		// This is needed because this will be executed after a (re)connect
		const subscription = client.subscribe(link, callback);
		onSubscribe(subscription);
	};

	client.onDisconnect = (frame) => {
		clearInterval(refreshIntervalId);
	};

	client.onStompError = function (frame) {
		// Will be invoked in case of error encountered at Broker
		// Bad login/passcode typically will cause an error
		// Complaint brokers will set `message` header with a brief message. Body may contain details.
		// Compliant brokers will terminate the connection after any error
		console.log("Broker reported error: " + frame.headers["MESSAGE"]);
		console.log("Additional details: " + frame.body);
	};

	client.activate();

	function updateDelta(recivedBody) {
		if (useDelta) {
			recivedBody.forEach((a) => {
				if (a.eventDate > lastReceivedDate) {
					lastReceivedDate = a.eventDate;
				}
			});
		}
	}
}

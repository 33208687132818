function calculateMsgPrice(price, textLength) {
	if (textLength <= 500) {
		return price;
	} else {
		const symbolsOverLimit = textLength - 500;
		return Math.ceil(symbolsOverLimit / 50) * 0.1 * price + price; // add 10% of price for every 50 symbols over 500 limit
	}
}

export default calculateMsgPrice;

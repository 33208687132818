import React from "react";
import { store } from "store";

// libs
import i18n from "i18n";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function buyButtonText(state) {
	const { fetching, message, product, amountWithPromoDiscount } = state;

	if (fetching) return <CircularProgress size={30} />;
	else if (amountWithPromoDiscount === 0) return i18n.t("Common.Next");
	else if (message)
		return `${i18n.t("Mesage.PurchaseForm.SendMsg.Button.Web")}`;
	else if (product?.productType === "COLLABORATION")
		return i18n.t("Collaboration.Leave.Button");
	else if (
		product?.productType === "AUTOGRAPH" ||
		product?.productType === "VIDEO_GREETING"
	)
		return `${i18n.t("Profile.MainProduct.BuyButton.Web")}`;
	else if (
		product?.productType === "EVENT" ||
		product?.productType === "PHYSICAL" ||
		product?.productType === "AUTO_REPLY"
	)
		return `${i18n.t("Profile.Product.BuyButton.Web")}`;
	else return i18n.t("Profile.MainProduct.BuyButton.Web");
}

export function buyButtonSubCaption(state) {
	const { amountWithPromoDiscount } = state;
	const {
		currencies: {
			selectedCurrency: { name },
		},
	} = store.getState();
	const isUSD = name === "USD";

	if (amountWithPromoDiscount === 0 || isUSD) return null;
	return i18n.t("PurchaseForm.BuyBtn.Subcaption.ApproximateUSDPrice", {
		usdAmount: amountWithPromoDiscount,
	});
}

import React from "react";

function Icon(props) {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.051 8.92C4.07111 8.6694 4.18488 8.43557 4.36964 8.26508C4.5544 8.09459 4.7966 7.99995 5.048 8H18.952C19.2034 7.99995 19.4456 8.09459 19.6304 8.26508C19.8151 8.43557 19.9289 8.6694 19.949 8.92L20.826 19.84C20.8481 20.1152 20.813 20.392 20.7228 20.6529C20.6327 20.9139 20.4895 21.1533 20.3023 21.3562C20.1151 21.5592 19.8879 21.7211 19.635 21.8319C19.3821 21.9427 19.1091 21.9999 18.833 22H5.167C4.89092 21.9999 4.61785 21.9427 4.36498 21.8319C4.11211 21.7211 3.88491 21.5592 3.6977 21.3562C3.51048 21.1533 3.3673 20.9139 3.27717 20.6529C3.18704 20.392 3.15191 20.1152 3.174 19.84L4.05 8.92H4.051Z"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16 11V6C16 4.93913 15.5786 3.92172 14.8284 3.17157C14.0783 2.42143 13.0609 2 12 2C10.9391 2 9.92172 2.42143 9.17157 3.17157C8.42143 3.92172 8 4.93913 8 6V11"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Icon;

import moment from "moment";
import i18n from "i18n";
import momentLocaleImporter from "./momentLocaleImporter";

const lang = i18n.languages[0];
momentLocaleImporter(lang);

export default function formatDate(eventDate) {
	const eventTime = eventDate;
	const currentTime = Date.now();
	const diffTime = currentTime - eventTime;
	const duration = moment.duration(diffTime, "milliseconds");
	const daysFromDate = duration.days();
	let formatedDate;

	formatedDate =
		daysFromDate > 1
			? moment(eventDate).locale(lang).format("LL")
			: moment(eventDate).locale(lang).fromNow();

	return formatedDate;
}

import { useEffect } from "react";

import { useStripe } from "@stripe/react-stripe-js";

export default function CheckoutRedirectMethod(props) {
	const { sessionId, active = true } = props;
	const stripe = useStripe();

	useEffect(() => {
		if (!!sessionId && !!stripe && active) createCheckoutSession();
	}, [sessionId, stripe, active]);

	const createCheckoutSession = async () => {
		try {
			await stripe.redirectToCheckout({
				sessionId: sessionId,
			});
		} catch (error) {
			console.log(error);
		}
	};

	return null;
}

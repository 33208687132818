import React from "react";
import i18n from "i18n";

// imgs
import HomeIcon from "imgs/club/nav/home";
import ShopIcon from "imgs/club/nav/shop";
import ChatIcon from "imgs/club/nav/comment";
import ActivityIcon from "imgs/club/nav/activity";
import EventsIcon from "imgs/club/nav/events";
import GroupChatIcon from "imgs/club/nav/group-chats";

const t = i18n.t.bind(i18n);

const navigationItems = [
	{
		id: "feed",
		title: t("Feed.Title"),
		icon: <HomeIcon />,
	},
	{
		id: "store",
		title: t("Store.Title"),
		icon: <ShopIcon />,
	},
	{
		id: "community",
		title: t("Discussions.Title"),
		icon: <ChatIcon />,
		hidden: ({ club }) => !club?.communityEnabled,
	},
	{
		id: "group-chats",
		title: t("GroupChats.Title"),
		icon: <GroupChatIcon className="fill-icon" />,
	},
	{
		id: "subs-events",
		title: t("Events.Title"),
		icon: <EventsIcon className="fill-icon" />,
		hidden: ({ club }) => !club?.hasEventsBySubscription,
	},
	{
		id: "activity",
		title: t("MyActivity.Tab"),
		icon: <ActivityIcon />,
		hidden: ({ user }) => !!!user,
	},
];

export default navigationItems;

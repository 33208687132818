import React, { Component } from "react";
import CloseBtn from "components/common/buttons/CloseBtn";
import "./css/modal.module.css";

export default class ModalHeader extends Component {
	render() {
		const { closeModal, title, style = {} } = this.props;

		return (
			<div styleName="modal-header" className="modal__header" style={style}>
				<div
					styleName="close-modal-btn"
					className="icon_md modal__header_close-btn"
				>
					<CloseBtn onClick={closeModal} />
				</div>
				<span className="title-5">{title}</span>
			</div>
		);
	}
}

import React from "react";
import { NavLink } from "react-router-dom";
import "./css/header.module.css";

// imgs
import { ReactComponent as Icon } from "imgs/header/shop.svg";

export default function OrdersLink({ counter }) {
	return (
		<li styleName="nav-item nav-item_with-counter">
			<NavLink to="/orders" styleName="nav-link" activeClassName="active-link">
				<div hidden={counter === 0} styleName="counter">
					<span styleName="counter-text">{counter}</span>
				</div>
				<Icon className="stroke-icon icon_xs" />
			</NavLink>
		</li>
	);
}

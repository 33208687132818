const formatList = {
	m_webp: (link) => {
		return {
			link: `${link}.webp`,
			type: "image/webp",
		};
	},

	l_webp: (link) => {
		return {
			link: `${link}_l.webp`,
			type: "image/webp",
		};
	},

	m: (link) => {
		return {
			link: `${link}_m`,
			type: "",
		};
	},

	orig: (link) => {
		return {
			link: link,
			type: "",
		};
	},
};

export default function getImageFormats(formats, link) {
	if (!link) return {};
	let exportedFormats = {};
	formats.forEach((format) => {
		exportedFormats[format] = formatList[format](link);
	});
	return exportedFormats;
}

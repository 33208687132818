const fondyConfig = {
	action: "https://api.fondy.eu/api/checkout/redirect/",
	server_callback_url: "https://test.pphndc.com:7280/api/transaction-status",
	response_url: window.location.origin + "/wallet/deposit-success",
	order_desc: "Payphone deposit",
	currency: "USD",
	merchant_id: "1447358",
	paymentKey: "HDsHrMK8l3AOSYsX0o2tIUncqhJk2aOG",
	order_id: "",
	showFondyMethod: false,
};

export default fondyConfig;

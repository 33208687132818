import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
// import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from "../reducers";

/* eslint-disable no-underscore-dangle */
export default function configureStore(initialState) {
	const composeEnhancers =
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	// const composed = compose(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(), applyMiddleware());

	const store = createStore(
		rootReducer,
		initialState,
		composeEnhancers(applyMiddleware(thunk))
	); //, applyMiddleware(serviceMiddleware)

	if (module.hot) {
		module.hot.accept("../reducers", () => {
			const nextRootReducer = require("../reducers");
			store.replaceReducer(nextRootReducer);
		});
	}

	return store;
}
/* eslint-enable */

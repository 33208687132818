import React, { useEffect, useState } from "react";
import "./css/cookies-modal.module.css";

// imgs
import COOKIES_IMG from "imgs/Cookies.svg";

// configs
import mediaQueries from "config/mediaQueries";

// libs
import Media from "react-media";
import { useTranslation } from "react-i18next";

export default function CookiesModal() {
	const { t } = useTranslation();
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		const isAllowed = JSON.parse(localStorage.getItem("allowCookies"));
		if (!isAllowed) setShowModal(true);
	}, []);

	const allowCookies = () => {
		localStorage.setItem("allowCookies", true);
		setShowModal(false);
	};

	return showModal ? (
		<div styleName="wrapper">
			<div styleName="content">
				<img className="icon_md mr_16" src={COOKIES_IMG} alt="cookies" />
				<div styleName="text" className="caption">
					<Media queries={mediaQueries}>
						{(matches) =>
							matches.sm ? t("CookiesModal.Text.Short") : t("CookiesModal.Text")
						}
					</Media>
				</div>
				<button className="pr-btn no-wrap" onClick={allowCookies}>
					{t("Common.GotIt")}
				</button>
			</div>
		</div>
	) : (
		false
	);
}

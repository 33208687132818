import React, { useState } from "react";
import "../css/settings.module.css";
import { useTranslation } from "react-i18next";
import appLangs from "./options/app-langs.json";
import PickList from "components/common/pick list/PickList";

const AppLang = ({ isTitleHidden = false }) => {
	const { t, i18n } = useTranslation();
	const [selectedList, setSelectedList] = useState(i18n.languages[0]);

	const changeAppLang = (lang) => {
		setSelectedList(lang);
		i18n.changeLanguage(lang);
		// document.location.reload();
	};

	return (
		<div styleName="content">
			<div
				styleName="centered-settings-block"
				className="center"
				hidden={isTitleHidden}
			>
				<span className="title-3">{t("Settings.AppLanguage.Title.Web")}</span>
			</div>
			<PickList
				type="radio"
				fullList={appLangs}
				selected={selectedList}
				onChange={(selected) => changeAppLang(selected)}
			/>
		</div>
	);
};

export default AppLang;

import React, { Component } from "react";
import GoogleAnalytics from "react-ga";

let withTracker = (WrappedComponent, options = {}) => {
	const HOC = class extends Component {
		render() {
			return <WrappedComponent {...this.props} />;
		}
	};

	return HOC;
};

if (process.env.REACT_APP_BUILD_ENV === "prod") {
	GoogleAnalytics.initialize("UA-145288257-1");
	GoogleAnalytics.plugin.require("ec");

	withTracker = (WrappedComponent, options = {}) => {
		const trackPage = (page) => {
			GoogleAnalytics.set({
				page,
				...options,
			});
			GoogleAnalytics.pageview(page);
		};

		const HOC = class extends Component {
			componentDidMount() {
				const page = this.props.location.pathname + this.props.location.search;
				trackPage(page);
			}

			componentDidUpdate(prevProps) {
				const currentPage =
					prevProps.location.pathname + prevProps.location.search;
				const nextPage =
					this.props.location.pathname + this.props.location.search;

				if (currentPage !== nextPage) {
					trackPage(nextPage);
				}
			}

			render() {
				return <WrappedComponent {...this.props} />;
			}
		};

		return HOC;
	};
}

export default withTracker;

import {
	GAsendEvent,
	GAsendСEvent,
	ECpurshaseForm,
	GApurshaseForm,
} from "./GA/ga";
import {
	pixelPurshaseFormOpen,
	pixelPurchaseEvent,
	pixelCatalogSearch,
	pixelProfileOpened,
	pixelCompleteRegistration,
} from "./fb-pixel/pixel";

function purshaseEvent(payload) {
	ECpurshaseForm(payload);
	GApurshaseForm(payload);
	pixelPurchaseEvent(payload);
}

function purshaseFormOpen(payload) {
	pixelPurshaseFormOpen(payload);
}

function catalogSearch(payload) {
	pixelCatalogSearch(payload);
}

function profileOpened(params) {
	pixelProfileOpened(params);
}

function completeRegistration(params) {
	pixelCompleteRegistration(params);
}

const analytic = {
	purshaseEvent,
	purshaseFormOpen,
	catalogSearch,
	profileOpened,
	completeRegistration,
};

export { analytic };

export default function analyticEvent(action) {
	typeof action === "string" ? GAsendEvent(action) : GAsendСEvent(action);
}

import React, { useState } from "react";

// hooks
import { useCheckIfOwnProfile } from "hooks";

// imgs
import PRESENT_ICON from "imgs/profile/Present.svg";
import PRESENT_WHITE_ICON from "imgs/profile/Present_white.svg";

// components
import DonateModal from "./DonateModal";
import { useTranslation } from "react-i18next";

export default function DonationBtn(props) {
	const {
		owner: { idUser, hasDonation },
	} = props;

	const isOwnProfile = useCheckIfOwnProfile(idUser);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const isVisiable = hasDonation && !isOwnProfile;
	const closeModal = () => setIsModalOpen(false);
	const handleClick = () => setIsModalOpen(true);

	return isVisiable ? (
		<>
			<BtnType handleClick={handleClick} {...props} />
			<DonateModal isOpen={isModalOpen} idUser={idUser} onClose={closeModal} />
		</>
	) : null;
}

const BtnType = (props) => {
	const { btnType } = props;

	switch (btnType) {
		case "icon":
			return <IconBtn {...props} />;
		case "label":
		default:
			return <LabelBtn {...props} />;
	}
};

const IconBtn = (props) => {
	const { handleClick, style } = props;

	return (
		<button className="btn_trans" onClick={handleClick} style={style}>
			<img src={PRESENT_ICON} alt="donate" className="icon_xs" />
		</button>
	);
};

const LabelBtn = (props) => {
	const { handleClick, style } = props;
	const { t } = useTranslation();

	return (
		<button
			className="pr-btn_purple-grad flex_centred fw"
			onClick={handleClick}
			style={style}
		>
			<img src={PRESENT_WHITE_ICON} alt="donate" className="icon_xs mr_8" />
			{t("MakeAGift.Text")}
		</button>
	);
};

import React from "react";
import "./css/buttons.module.css";
import CLOSE_ICON from "imgs/Close.svg";

export default function CloseBtn({ onClick = () => {} }) {
	return (
		<div
			styleName="close-btn"
			className="icon_md__round flex_centred mr_16"
			onClick={() => onClick()}
		>
			<img src={CLOSE_ICON} alt="close" />
		</div>
	);
}

import { createSlice } from "@reduxjs/toolkit";
import { API } from "functions";
import currenciesSymbols from "json/currenciesSymbols";

const initialCurrency = localStorage.getItem("currency")
	? JSON.parse(localStorage.getItem("currency"))
	: {
			currencies: { USD: 1 },
			selectedCurrency: {
				name: "USD",
				rate: 1,
				symbol: currenciesSymbols.USD,
				selectedManualy: false,
			},
	  };

// Slice
const slice = createSlice({
	name: "currencies",
	initialState: initialCurrency,
	reducers: {
		getCurrenciesSuccess: (state, action) => {
			state.currencies = Object.assign(state.currencies, action.payload);
			storeInLocalstorage({ currencies: action.payload });
		},
		selectCurrencySuccess: (state, action) => {
			state.selectedCurrency = action.payload;
			storeInLocalstorage({ selectedCurrency: action.payload });
		},
	},
});
export default slice.reducer;
// Actions
const { getCurrenciesSuccess, selectCurrencySuccess } = slice.actions;
export const storeCurrencies = () => async (dispatch, getState) => {
	try {
		const response = await API.get("get-exchange-rates");
		dispatch(getCurrenciesSuccess(response.data));
		currencyAutoselect(dispatch, getState);
	} catch (e) {
		return console.error(e);
	}
};

export const selectCurrency = (
	selectedCurrencyName,
	selectedManualy = true
) => async (dispatch, getState) => {
	const {
		currencies: { currencies },
	} = getState();

	dispatch(
		selectCurrencySuccess({
			name: selectedCurrencyName,
			rate:
				selectedCurrencyName === "USD" ? 1 : currencies[selectedCurrencyName],
			symbol: currenciesSymbols[selectedCurrencyName],
			selectedManualy: selectedManualy,
		})
	);
};

function storeInLocalstorage(payload) {
	const current =
		JSON.parse(localStorage.getItem("currency")) || initialCurrency;
	const update = Object.assign(current, payload);
	localStorage.setItem("currency", JSON.stringify(update));
}

function currencyAutoselect(dispatch, getState) {
	const {
		currencies: {
			selectedCurrency: { selectedManualy },
		},
		user: { user },
	} = getState();

	if (!selectedManualy) {
		const userCurrency = user?.currency;
		userCurrency
			? currencyAutoselectByUserSettings(dispatch, userCurrency)
			: currencyAutoselectByCountry(dispatch);
	}
}

function currencyAutoselectByCountry(dispatch) {
	const country = localStorage.getItem("country");
	switch (country) {
		case "ua":
			dispatch(selectCurrency("UAH", false));
			break;
		default:
			break;
	}
}

function currencyAutoselectByUserSettings(dispatch, currency) {
	switch (currency) {
		case "UAH":
			dispatch(selectCurrency("UAH", false));
			break;
		case "USD":
			dispatch(selectCurrency("USD", false));
			break;
		default:
			break;
	}
}

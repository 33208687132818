import { createSlice } from "@reduxjs/toolkit";
import { API } from "functions";

const initialState = {
	product: null,
	productOwner: null,
};

// Slice
const slice = createSlice({
	name: "product",
	initialState: initialState,
	reducers: {
		getProductSuccess: (state, action) => {
			state.product = action.payload;
		},
		getProductOwnerSuccess: (state, action) => {
			state.productOwner = action.payload;
		},
		deleteProductSuccess: (state) => {
			state.product = null;
			state.productOwner = null;
		},
	},
});
export default slice.reducer;
// Actions
const {
	getProductSuccess,
	deleteProductSuccess,
	getProductOwnerSuccess,
} = slice.actions;

export const getProduct = (idProduct, idUser) => async (dispatch) => {
	dispatch(fetchProduct(idProduct));
	dispatch(fetchProductOwner(idUser));
};

export const getAuctionCheckout = (idProduct, idUser) => async (dispatch) => {
	dispatch(fetchAuctionCheckout(idProduct));
	dispatch(fetchProductOwner(idUser));
};

export const updateProduct = (product) => async (dispatch) => {
	try {
		dispatch(getProductSuccess(product));
	} catch (e) {
		return console.error(e);
	}
};

export const deleteProduct = () => async (dispatch) => {
	dispatch(deleteProductSuccess());
};

const fetchProductOwner = (idUser) => async (dispatch) => {
	if (idUser) {
		try {
			const response = await API.get(`get-user-by-id/${idUser}`);
			dispatch(getProductOwnerSuccess(response.data));
		} catch (e) {
			return console.error(e);
		}
	}
};

const fetchProduct = (idProduct) => async (dispatch) => {
	if (idProduct) {
		try {
			const response = await API.get(`get-product-by-id/${idProduct}`);
			dispatch(getProductSuccess(response.data));
		} catch (e) {
			return console.error(e);
		}
	}
};

const fetchAuctionCheckout = (idProduct) => async (dispatch) => {
	if (idProduct) {
		try {
			const response = await API.get(`get-product-by-id/${idProduct}`);
			const product = response.data;
			product.auctionCheckout = (() => {
				switch (product.productStatus) {
					case "WAITING_FOR_PAYMENT":
						return "UNPAID";
					case "WAITING_FOR_INFO":
						return "PAID";
					default:
						return undefined;
				}
			})();
			if (product.auctionCheckout === "PAID") {
				product.standardRate = 0;
			}
			dispatch(getProductSuccess(response.data));
		} catch (e) {
			return console.error(e);
		}
	}
};

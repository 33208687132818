import { MODAL_OPEN } from '../constants/list';

const initialState = {
  modalIsOpen: false
}

export default function modalState(state = initialState, action) {
  switch (action.type) {
    case MODAL_OPEN:
      return { ...state, modalIsOpen: action.payload }

    default:
      return state;
  }
}

import { combineReducers } from "redux";
import modalState from "./modal";
import resetPassToken from "./resetPassToken";
import user from "./user";
import authToken from "./authToken";
import callStatus from "./callStatus";
import wsEvent from "./wsEvent";
import setPinRedirect from "./setPinRedirect";
import currencies from "../ducks/currencies";
import newEventsCount from "../ducks/newEventsCount";
import modal from "../ducks/modal";
import product from "components/pages/purchase form/ProductSlice";
import bids from "components/pages/auction/BidsSlice";
import feed from "components/common/feed/FeedSlice";
import communityFeed from "components/common/community/FeedSlice";
import explore from "components/pages/explore/ExploreSlice";
import profile from "components/pages/profile/ProfileSlice";
import chat from "components/pages/chat/slice/chat.slice";

export default combineReducers({
	modalState,
	resetPassToken,
	user,
	authToken,
	callStatus,
	wsEvent,
	setPinRedirect,
	currencies,
	product,
	bids,
	newEventsCount,
	feed,
	modal,
	communityFeed,
	explore,
	profile,
	chat,
});

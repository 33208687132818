function aTokenInLocalstorage() {
    let aToken = JSON.parse(localStorage.getItem('aToken'));
    if (aToken !== null && aToken !== 'null' && aToken !== undefined && aToken !== 'undefined' && aToken !== '') {
        return true
    }
    else {
        return false
    }
}

export default aTokenInLocalstorage
import React from "react";

function Icon(props) {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.79 3.603C17.9023 3.45444 17.9709 3.27748 17.988 3.09205C18.0052 2.90662 17.9702 2.72008 17.887 2.55346C17.8039 2.38683 17.6758 2.24674 17.5173 2.14895C17.3589 2.05117 17.1762 1.99958 16.99 2H10.58C10.4041 2.00003 10.2313 2.04646 10.0791 2.1346C9.92688 2.22274 9.8006 2.34947 9.713 2.502L4.86 10.958C4.77289 11.1101 4.72729 11.2824 4.72775 11.4577C4.72822 11.6329 4.77474 11.805 4.86265 11.9566C4.95057 12.1082 5.07679 12.2341 5.22866 12.3216C5.38054 12.409 5.55274 12.455 5.728 12.455H9L5.916 20.52C5.471 21.54 6.676 22.473 7.486 21.735L20 9.331H13.461L17.789 3.603H17.79Z"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Icon;

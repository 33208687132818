import { createSlice } from "@reduxjs/toolkit";
import { store } from "store";
import i18n from "i18n";
const t = i18n.t.bind(i18n);

// Slice
const slice = createSlice({
	name: "modal",
	initialState: {
		type: null,
		options: null,
	},
	reducers: {
		openModalSuccess: (state, action) => {
			state.type = action.payload.type;
			state.options = action.payload.options;
		},
		openSnackbarSuccess: (state, action) => {
			state.type = action.payload.type;
			state.options = action.payload.options;
		},
		closeModalSuccess: (state) => {
			state.type = null;
		},
	},
});
export default slice.reducer;

// Actions
const {
	openModalSuccess,
	openSnackbarSuccess,
	closeModalSuccess,
} = slice.actions;

export const openModal = async (config) => {
	try {
		const options = typeof config === "string" ? { type: config } : config;
		store.dispatch(openModalSuccess(options));
	} catch (e) {
		return console.error(e);
	}
};

export const closeModal = () => async (dispatch) => {
	try {
		dispatch(closeModalSuccess());
	} catch (e) {
		return console.error(e);
	}
};

export const openSnackbar = async (config) => {
	try {
		const options = getOptions(config);
		store.dispatch(openSnackbarSuccess({ type: "snackbar", options }));
	} catch (e) {
		return console.error(e);
	}
};

export const openSavedSnackbar = async () => {
	try {
		const options = { text: t("Saved.Title") };
		store.dispatch(openSnackbarSuccess({ type: "snackbar", options }));
	} catch (e) {
		return console.error(e);
	}
};

export const openErrorSnackbar = async (
	config = t("Core.Error.SomethingWrong.Text")
) => {
	try {
		const options = getOptions(config);
		store.dispatch(openSnackbarSuccess({ type: "snackbar-error", options }));
	} catch (e) {
		return console.error(e);
	}
};

const getOptions = (options) => {
	return typeof options === "string" ? { text: options } : options;
};

import React from "react";
import { NavLink } from "react-router-dom";
import "./css/header.module.css";

// imgs
import { ReactComponent as ThreadIcon } from "imgs/header/inbox.svg";

export default function ChatLink({ counter }) {
	return (
		<li styleName="nav-item nav-item_with-counter">
			<NavLink to="/chat" styleName="nav-link" activeClassName="active-link">
				<div hidden={counter === 0} styleName="counter">
					<span styleName="counter-text">{counter}</span>
				</div>
				<ThreadIcon className="stroke-icon icon_xs" />
			</NavLink>
		</li>
	);
}

import React from 'react';

function Icon(props) {
    return <svg className="wallet-icon" width="26" height="24" viewBox="0 0 26 24" xmlns="http://www.w3.org/2000/svg">
        <rect className="wallet-icon-rect-base" x="1" y="7" width="24" height="16" rx="3" strokeWidth="2" />
        <rect className="wallet-icon-rect" x="3" y="3" width="20" height="2" rx="1" />
        <rect className="wallet-icon-rect" x="5" width="16" height="2" rx="1" />
    </svg>
}

export default Icon

import { createSlice } from "@reduxjs/toolkit";
import { analytic } from "analytics";
import { isPublicProfile } from "functions";
import { API } from "functions";
import { store } from "store";
import activityTabs from "./club/activity/config/activityTabs";
import navigationItems from "./club/config/navigationItems";

const initialState = {
	profile: null,
	mainProduct: null,
	products: [],
	reviews: {
		totalScores: 0,
		lastComment: "",
		averageScore: 0,
	},
	charityFund: null,
	latestCompleteProducts: null,
	charityTotal: null,
	club: null,
	activeTab: navigationItems[0],
	activity: {
		comments: {
			items: [],
			currentPage: 0,
			isLastPageLoaded: false,
			delta: 0,
		},
		activeTab: activityTabs[0],
	},
	clubProducts: {
		memberProducts: [],
		subscriptions: [],
		initial: true,
	},
	subsEvents: [],
	groupChats: null,
	idChat: null,
};

// Slice
const slice = createSlice({
	name: "profile",
	initialState: initialState,
	reducers: {
		storeAllProfileData: (state, action) => {
			for (const key in action.payload) {
				state[key] = action.payload[key];
			}
		},
		storeProfile: (state, action) => {
			state.profile = action.payload;
		},
		storeClub: (state, action) => {
			state.club = action.payload;
		},
		storeInitProfile: (state) => {
			for (const key in initialState) {
				state[key] = initialState[key];
			}
		},
		storeActiveTab: (state, action) => {
			state.activeTab = action.payload;
		},
		storeProducts: (state, action) => {
			state.products = action.payload;
		},
		storeActivity: (state, action) => {
			state.activity = action.payload;
		},
		storeClubProducts: (state, action) => {
			state.clubProducts = action.payload;
		},
		storeSubsEvents: (state, action) => {
			state.subsEvents = action.payload;
		},
		storeGroupChats: (state, action) => {
			state.groupChats = action.payload;
		},
	},
});
export default slice.reducer;

// Actions
const {
	storeAllProfileData,
	storeClub,
	storeInitProfile,
	storeActiveTab,
	storeProducts,
	storeActivity,
	storeClubProducts,
	storeSubsEvents,
	storeGroupChats,
} = slice.actions;

export const removeProfile = () => {
	store.dispatch(storeInitProfile());
};

export const getProfile = async ({ id, history }) => {
	try {
		const {
			data: {
				userDTO,
				selfUserDTO,
				charityFundDTOs,
				totalScores,
				lastComment,
				products,
				latestCompleteProducts,
				charityTotal,
				club,
				hasEventsBySubscription,
				idChat,
			},
		} = await API.get(`get-user-profile-data/${id}/$lang`);
		const isSelfProfile = !!selfUserDTO;
		const user = isSelfProfile ? selfUserDTO : userDTO;

		switch (true) {
			case isSelfProfile:
				storeProfileData();
				if (!!club) store.dispatch(storeClub(club));
				break;
			case !!club:
				store.dispatch(storeClub({ ...club, hasEventsBySubscription }));
				break;
			case isPublicProfile(user):
				// hide profile if user is not expert
				storeProfileData();
				break;
			default:
				history && history.push("/");
				break;
		}

		function storeProfileData() {
			const profileAnalData = {
				user,
				products,
			};
			analytic.profileOpened(profileAnalData);
			store.dispatch(
				storeAllProfileData({
					profile: user,
					charityFund: charityFundDTOs[0],
					reviews: {
						totalScores,
						lastComment,
						averageScore: user.averageScore,
					},
					products,
					latestCompleteProducts,
					charityTotal,
					idChat,
				})
			);
		}
	} catch (e) {
		history && history.push("/");
		console.log(e);
	}
};

export const changeActiveTab = (tab) => store.dispatch(storeActiveTab(tab));

export const saveProducts = (products) =>
	store.dispatch(storeProducts(products));

export const saveClubProducts = (products) =>
	store.dispatch(storeClubProducts(products));

export const saveSubsEvents = (events) =>
	store.dispatch(storeSubsEvents(events));

export const saveGroupChats = (events) =>
	store.dispatch(storeGroupChats(events));

export const saveActivityComments = ({ items: newComments, totalPages }) => {
	const {
		profile: {
			activity: { comments, activeTab },
		},
	} = store.getState();

	const currentComments = comments.items;
	const updPage = comments.currentPage + 1;
	const updPostsItems = [...currentComments, ...newComments];
	const updPosts = {
		items: updPostsItems,
		currentPage: updPage,
		isLastPageLoaded: updPage >= totalPages,
		delta: comments.delta,
	};
	const updActivity = { comments: updPosts, activeTab };
	store.dispatch(storeActivity(updActivity));
};

export const saveActivityCommentsByDelta = ({ items: newComments, delta }) => {
	const {
		profile: {
			activity: { comments, activeTab },
		},
	} = store.getState();

	const currentComments = comments.items;
	const updPostsItems = [...newComments, ...currentComments];
	const updPosts = {
		items: updPostsItems,
		currentPage: comments.currentPage,
		isLastPageLoaded: comments.currentPage,
		delta: delta,
	};
	const updActivity = { comments: updPosts, activeTab };
	store.dispatch(storeActivity(updActivity));
};

export const saveActivityCommentsDelta = (delta) => {
	const {
		profile: {
			activity: { comments, activeTab },
		},
	} = store.getState();

	const updPosts = {
		items: comments.items,
		currentPage: comments.currentPage,
		isLastPageLoaded: comments.currentPage,
		delta: delta,
	};
	const updActivity = { comments: updPosts, activeTab };
	store.dispatch(storeActivity(updActivity));
};

export const changeActiveActivityTab = (tab) => {
	const {
		profile: {
			activity: { comments },
		},
	} = store.getState();

	const updActivity = { comments, activeTab: tab };
	store.dispatch(storeActivity(updActivity));
};

import { createSlice } from "@reduxjs/toolkit";
import { API } from "functions";

const initialState = {
	bids: [],
};

// Slice
const slice = createSlice({
	name: "bids",
	initialState: initialState,
	reducers: {
		getBidsSuccess: (state, action) => {
			state.bids = action.payload;
		},
		deleteBidsSuccess: (state) => {
			state.bids = [];
		},
	},
});
export default slice.reducer;

// Actions
const { getBidsSuccess, deleteBidsSuccess } = slice.actions;

export const getBids = (idProduct) => async (dispatch) => {
	try {
		const data = {
			page: 0,
			size: 100,
		};
		const response = await API.post(`get-bids/${idProduct}`, data);
		dispatch(getBidsSuccess(response.data.items));
	} catch (e) {
		return console.error(e);
	}
};

export const newBids = (socketBids) => (dispatch, getState) => {
	const {
		bids: { bids: currentBids },
	} = getState();
	const mergedBids = [...currentBids, ...socketBids];
	const unique = [];
	const map = new Map();
	// filter unique bids from new socket bids & stored bids
	for (const item of mergedBids) {
		if (!map.has(item.id)) {
			map.set(item.id, true);
			unique.push(item);
		}
	}
	// sort bids by rate
	unique.sort((a, b) => b.bid - a.bid);
	dispatch(getBidsSuccess(unique));
};

export const deleteBids = () => async (dispatch) => {
	dispatch(deleteBidsSuccess());
};

const mediaQueries = {
	xl: {
		minWidth: 1025,
	},
	lg: {
		maxWidth: 1024,
	},
	md: {
		maxWidth: 768,
	},
	sm: {
		maxWidth: 600,
	},
	xs: {
		maxWidth: 375,
	},
	xxs: {
		maxWidth: 359,
	},
};

export default mediaQueries;

import React, { useState } from "react";
import "./css/header.module.css";
import LangsModal from "../modal/LangsModal";
import i18n from "i18n";

export default function LangBtn() {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const langLabel = (() => {
		const code = i18n.languages[0];
		const label = code === "uk" ? "ua" : code;
		return label.toUpperCase();
	})();

	return (
		<>
			<div styleName="lang-btn" onClick={() => setIsModalOpen(true)}>
				{langLabel}
			</div>
			<LangsModal
				onSumbit={() => setIsModalOpen(false)}
				isOpen={isModalOpen}
				closeModal={() => setIsModalOpen(false)}
			/>
		</>
	);
}

import React from "react";
import "./css/header.module.css";

// components
import LoggedInNav from "./LoggedInNav";
import LoggedOutNav from "./LoggedOutNav";

export default function Header(props) {
	const { user } = props;
	const loggedUser =
		user?.user?.status === "ACTIVE" || user?.user?.status === "HIDDEN"
			? user.user
			: null;

	return (
		<header styleName="header">
			{loggedUser ? <LoggedInNav user={loggedUser} /> : <LoggedOutNav />}
		</header>
	);
}

import React from "react";
import "./css/donate-modal.module.css";

// libs
import { useTranslation } from "react-i18next";

// components
import Modal from "components/common/modal/Modal";
import ContactFields from "./ContactFields";

export default function LoginModal(props) {
	const {
		isOpen,
		email,
		emailIV,
		phoneNumber,
		onPhoneChange,
		onEmailChange,
		onClose,
		onSubmit,
		onBackBtnClick,
	} = props;
	const { t } = useTranslation();
	const backBtnHandle = () => onBackBtnClick("variations");

	return (
		<Modal
			isOpen={isOpen}
			closeModal={onClose}
			noPadding
			maxWidth="400px"
			borderRadius="16px"
		>
			<div styleName="donate-modal" className="scrollable-content">
				<div styleName="donate-modal-content">
					<div className="flex_centred__h">
						<button className="back-btn" onClick={backBtnHandle} />
						<span className="title-5 ml_16">
							{t("AuthorizationRequired.Title")}
						</span>
					</div>
					<ContactFields
						email={email}
						emailIV={emailIV}
						phoneNumber={phoneNumber}
						onPhoneChange={onPhoneChange}
						onEmailChange={onEmailChange}
					/>
					<button
						className="pr-btn_prim fw mb_16"
						disabled={!emailIV}
						onClick={onSubmit}
					>
						{t("Common.Next")}
					</button>
				</div>
			</div>
		</Modal>
	);
}

export default function paymenTypes(t) {
	return [
		{
			id: false,
			value: "one-time",
			title: t("OneTime.Handout.Title"),
		},
		{
			id: true,
			value: "regular",
			title: t("RecurringPayment.Title"),
			caption: t("RecurringPayment.SubTitle"),
		},
	];
}

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "redux/ducks/modal";

// components
import Snackbar from "../snackbar/Snackbar";
import AuctionWinnerModal from "./AuctionWinnerModal";
import AuctionWinnerUnpaidModal from "./AuctionWinnerUnpaidModal";
import NeedToLoginModal from "./NeedToLoginModal";
import { DonateModal } from "../donation btn";

export default function ReduxModal() {
	const { type, options } = useSelector((state) => state.modal);
	const dispatch = useDispatch();
	let location = useLocation();
	const closeModalHandler = () => {
		dispatch(closeModal());
	};

	// close modal on route change
	useEffect(() => {
		// add condition to keep open if such modal setting present
		if (!!type) dispatch(closeModal());
	}, [location.pathname]);

	switch (type) {
		case null:
			return null;
		case "snackbar":
			return (
				<Snackbar isOpen handleClose={closeModalHandler} text={options.text} />
			);
		case "snackbar-error":
			return (
				<Snackbar
					isOpen
					autoHideDuration={5000}
					handleClose={closeModalHandler}
					text={options.text}
				/>
			);
		case "NeedToLoginModal":
			return <NeedToLoginModal isOpen closeModal={closeModalHandler} />;
		case "AuctionWinnerUnpaidModal":
			return (
				<AuctionWinnerUnpaidModal
					isOpen
					closeModal={closeModalHandler}
					product={options.product}
				/>
			);
		case "AuctionWinnerModal":
			return (
				<AuctionWinnerModal
					isOpen
					closeModal={closeModalHandler}
					product={options.product}
				/>
			);
		case "DonateModal":
			return (
				<DonateModal
					isOpen
					idUser={options.idUser}
					onClose={closeModalHandler}
				/>
			);
		default:
			return null;
	}
}

import React, { useState } from "react";
import "./css/donate-modal.module.css";

// libs
import { useTranslation } from "react-i18next";

// components
import Modal from "components/common/modal/Modal";
import DonationVariations from "./DonationVariations";

export default function DonateVariationModal(props) {
	const {
		inputSum,
		activeVariation,
		donation,
		isOpen,
		onClose,
		onSubmit,
		changeActiveVariation,
		onInputSumChange,
	} = props;
	const { t } = useTranslation();
	const [inputErrorText, setInputErrorText] = useState("");

	const onInputErrorTextChange = (text) => setInputErrorText(text);
	const submitHandle = () => onSubmit("type");

	const isBtnDisabled =
		!activeVariation ||
		(activeVariation === "manual" && (inputErrorText || !!!inputSum));

	return (
		<Modal
			isOpen={isOpen}
			closeModal={onClose}
			header={{
				title: t("MakeAGift.Text"),
			}}
			noPadding
			maxWidth="400px"
			borderRadius="16px"
		>
			<div styleName="donate-modal" className="scrollable-content">
				<div styleName="donate-modal-content">
					<DonationVariations
						inputSum={inputSum}
						donation={donation}
						activeVariation={activeVariation}
						inputErrorText={inputErrorText}
						changeActiveVariation={changeActiveVariation}
						onInputSumChange={onInputSumChange}
						onInputErrorTextChange={onInputErrorTextChange}
					/>
					<button
						className="pr-btn_prim fw mb_16"
						disabled={isBtnDisabled}
						onClick={submitHandle}
					>
						{t("Common.Next")}
					</button>
				</div>
			</div>
		</Modal>
	);
}

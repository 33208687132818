import React, { Component } from "react";
import ReactDOM from "react-dom";
import MaterialSnackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const modalRoot = document.getElementById("modal-root");

class Snackbar extends Component {
	constructor(props) {
		super(props);
		this.handleClose = this.handleClose.bind(this);

		this.targetElement = null;
		this.modalRef = React.createRef();
		// this.el = document.createElement('div');
	}

	// componentDidMount() {
	//     modalRoot.appendChild(this.el);
	// }

	// componentWillUnmount() {
	//     modalRoot.removeChild(this.el);
	// }

	handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		this.props.handleClose();
	};

	render() {
		const { isOpen, text, autoHideDuration = 2000 } = this.props;

		return isOpen
			? ReactDOM.createPortal(
					<MaterialSnackbar
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						open
						autoHideDuration={autoHideDuration}
						onClose={this.handleClose}
						message={text}
						action={
							<React.Fragment>
								<IconButton
									size="medium"
									aria-label="close"
									color="inherit"
									onClick={this.handleClose}
								>
									<CloseIcon fontSize="default" />
								</IconButton>
							</React.Fragment>
						}
					/>,
					modalRoot
			  )
			: null;
	}
}

export default Snackbar;

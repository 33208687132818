import React from "react";

// utils
import { priceLabels } from "./utils";

// libs
import { useTranslation } from "react-i18next";

// components
import { TileBtn } from "../tile picker";
import { TextField } from "@material-ui/core";

const inputLimits = {
	min: 1,
	max: 2000,
};

const getInputErrorText = (t, value) => {
	if (isNaN(value)) return t("Core.Error.FieldIsInvalid.Text");
	else if (value < inputLimits.min) {
		return t("Core.Error.ValueTooSmall.Text.WithArgs.Web", {
			amount: inputLimits.min,
		});
	} else if (value > inputLimits.max) {
		return t("Core.Error.ValueTooBig.Text.WithArgs.Web", {
			amount: inputLimits.max,
		});
	} else return "";
};

export default function DonationVariations(props) {
	const {
		donation: { variations },
		inputSum,
		activeVariation,
		inputErrorText,
		changeActiveVariation,
		onInputSumChange,
		onInputErrorTextChange,
	} = props;
	const { t } = useTranslation();
	const { priceLabel, inputPriceCaptionLabel } = priceLabels();

	const inputHandle = (ev) => {
		const value = ev.target.value;
		onInputSumChange(value);
		onInputErrorTextChange(getInputErrorText(t, value));
	};

	return variations ? (
		<>
			{variations.map((variation) => {
				const { id, name, price } = variation;
				return (
					<TileBtn
						key={id}
						id={id}
						isActive={activeVariation === id}
						title={name}
						price={priceLabel(price)}
						priceCaption={inputPriceCaptionLabel(price)}
						onClick={changeActiveVariation}
					/>
				);
			})}
			<TileBtn
				id={"manual"}
				isActive={activeVariation === "manual"}
				title={t("Donate.SetManually.Item")}
				price={priceLabel(inputSum)}
				priceCaption={inputPriceCaptionLabel(inputSum)}
				onClick={changeActiveVariation}
				input={
					activeVariation === "manual" ? (
						<TextField
							label={t("Product.Buy.Label")}
							value={inputSum}
							onChange={inputHandle}
							margin="normal"
							variant="filled"
							inputProps={{
								inputMode: "numeric",
								pattern: "[0-9]*",
								maxLength: 4,
							}}
							helperText={inputErrorText}
							error={!!inputErrorText}
						/>
					) : null
				}
			/>
		</>
	) : null;
}

import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

export default function StripeProvider(props) {
	const { children } = props;

	return <Elements stripe={stripePromise}>{children}</Elements>;
}

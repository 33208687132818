import errorCodeTexts from "./errorCodeTexts";

const errorCodes = Object.keys(errorCodeTexts);

const isErrorHandled = (error) => {
	const code = error?.response?.data?.error;
	return code ? isErrorCodeHandled(code) : false;
};

const isErrorCodeHandled = (errorCode) => {
	const code = String(errorCode);
	return errorCodes.includes(code);
};

export { errorCodeTexts, errorCodes, isErrorHandled, isErrorCodeHandled };

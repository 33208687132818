import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function LoginLink() {
	const { t } = useTranslation();

	return (
		<li>
			<Link className="nav-link" to="/login">
				{t("Auth.LogIn.Button")}
			</Link>
		</li>
	);
}

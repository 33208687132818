import { STORE_USER } from "../constants/list";
import { logoutUser } from "functions";
import { openModal } from "redux/ducks/modal";

export function storeUserInfo(user) {
	if (
		!(
			user.status === "ACTIVE" ||
			user.status === "HIDDEN" ||
			user.status === "REGISTRATION"
		)
	) {
		logoutUser();
	} else if (user.status === "REGISTRATION") {
		// to do: get rid of window routing (mb use connected-react-router?)
		if (window.location.pathname !== "/register")
			window.location.href = "/register";
		return {
			type: STORE_USER,
			payload: user,
		};
	} else {
		checkUsersScreen(user);
		return {
			type: STORE_USER,
			payload: user,
		};
	}
}

export function storeRegisterUser(user) {
	return {
		type: STORE_USER,
		payload: user,
	};
}

function checkUsersScreen(user) {
	if (Array.isArray(user.screens) && user.screens.length) {
		if (window.location.href.includes("auction-winner")) return false;
		const screen = user.screens[0];
		let modalType;
		switch (screen.screenType) {
			case "AUCTION_PAID_NEED_INFO":
				modalType = "AuctionWinnerModal";
				break;
			case "AUCTION_UNPAID":
				modalType = "AuctionWinnerUnpaidModal";
				break;
			default:
				break;
		}

		if (modalType) {
			openModal({
				type: modalType,
				options: {
					product: screen,
				},
			});
		}
	}
}

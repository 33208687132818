import React, { useEffect, useState } from "react";

// utils
import { validateEmail, phoneNumberLabel, checkCountry } from "functions";

// libs
import { useTranslation } from "react-i18next";
import ReactPhoneInput from "react-phone-input-mui";
import { isValidPhoneNumber } from "react-phone-number-input";
import { TextField } from "@material-ui/core";

export default function ContactFields(props) {
	const { email, phoneNumber, onPhoneChange, onEmailChange } = props;
	const { t } = useTranslation();
	const [userCountry, setUserCountry] = useState("us");
	const [showEmailTooltip, setShowEmailTooltip] = useState(false);

	useEffect(() => {
		checkCountry((country) => setUserCountry(country));
	}, []);

	const handleChangePhoneNumber = (number) => {
		onPhoneChange({
			phoneNumber: number,
			phoneNumberIV: isValidPhoneNumber(number),
		});
	};

	const updateEmail = (ev) => {
		const email = ev.target.value;
		onEmailChange({
			email: email,
			emailIV: validateEmail(email),
		});
		setShowEmailTooltip(!validateEmail(email));
	};

	return (
		<>
			<TextField
				label={t("Product.PurchaseForm.YourEmail.Label.Web")}
				value={email}
				onChange={updateEmail}
				variant="filled"
				type="email"
				helperText={showEmailTooltip ? t("Auth.Email.TextField.Error") : ""}
				error={showEmailTooltip}
			/>
			<ReactPhoneInput
				id="component-filled"
				value={phoneNumber}
				onChange={handleChangePhoneNumber}
				component={TextField}
				defaultCountry={userCountry}
				inputExtraProps={{
					autoComplete: "phone",
					variant: "filled",
					label: phoneNumberLabel(),
				}}
			/>
		</>
	);
}

const preventUserSyncPages = [
	"/wallet/deposit/",
	"/feed-subscription/",
	"/wallet/withdraw/",
	"/revert-email/",
	"/confirm-corporate-email/",
	"/confirm-email/",
	"/email-unsubscribe/",
	"/mob-app/link/",
];
// pages expect new bearer in path

const pageRegex = (path) => {
	// form regex /\/page\/([^)]+)\//,
	const regex = new RegExp("\\" + path + "([^)]+)\\/");
	return regex;
};

export default function isPagePreventUserSync(page) {
	const path = window.location.pathname;
	return preventUserSyncPages.some((page) => {
		const regex = pageRegex(page);
		return !!path.match(regex);
	});
}

import React from "react";
import { StripeProvider } from "..";
import CheckoutRedirectMethod from "./CheckoutRedirectMethod";

export default function CheckoutRedirect(props) {
	return (
		<StripeProvider>
			<CheckoutRedirectMethod {...props} />
		</StripeProvider>
	);
}

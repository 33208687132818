import { store } from "store";
import APIrequest from "APIcalls/APIrequest";
import { storeUserInfo } from "redux/actions/UserAction";

function updateUser(user) {
	if (!user) getUserFull();
	else storeUser(user);
}

async function getUserFull() {
	try {
		let response = await APIrequest.get_user_full();
		if (response.status === 200) storeUser(response.data);
	} catch (error) {}
}

function storeUser(user) {
	// add condition if (currentState.lastUpdatedDate !== response.data.lastUpdatedDate) {
	store.dispatch(storeUserInfo(user));
}

export default updateUser;

import React from 'react';

function Icon(props) {
    return <svg fill={props.fill} width="18px" height="20px" viewBox="0 0 18 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
            <g id="Profile-icon" fillRule="nonzero">
                <path d="M9,8 C10.6569,8 12,6.65685 12,5 C12,3.34315 10.6569,2 9,2 C7.3431,2 6,3.34315 6,5 C6,6.65685 7.3431,8 9,8 Z M9,10 C6.23858,10 4,7.76142 4,5 C4,2.23858 6.23858,0 9,0 C11.7614,0 14,2.23858 14,5 C14,7.76142 11.7614,10 9,10 Z M18,19 C18,19.5523 17.5523,20 17,20 C16.4477,20 16,19.5523 16,19 L16,17 C16,15.3431 14.6569,14 13,14 L5,14 C3.34315,14 2,15.3431 2,17 L2,19 C2,19.5523 1.55228,20 1,20 C0.44772,20 0,19.5523 0,19 L0,17 C0,14.2386 2.23858,12 5,12 L13,12 C15.7614,12 18,14.2386 18,17 L18,19 Z" id="Shape"></path>
            </g>
        </g>
    </svg>
}

export default Icon
import ReactGA from "react-ga";

// default events
export function GAsendEvent(action) {
	ReactGA.event({
		category: "UserAction",
		action: action,
	});
}
// send custom event
export function GAsendСEvent(payload) {
	ReactGA.event(payload);
}

// custom events list
export function GApurshaseForm(state = {}) {
	const { product: { productType } = {}, expert: { name } = {} } = state;
	const label = `${productType || "MESSAGE"}, user: ${name}`;

	GAsendСEvent({
		category: "Button clicked",
		action: "Purchase form: buy button clicked",
		label: label,
	});

	ReactGA.plugin.execute("ec", "clear");
}

// ecommerce
export function ECpurshaseForm(state = {}) {
	const {
		product: { idProduct, productType } = {
			idProduct: "",
			productType: "MESSAGE",
		},
		expert,
		amountWithPromoDiscount,
		amount,
		promoCode,
	} = state;

	ReactGA.plugin.execute("ec", "addProduct", {
		id: idProduct,
		name: productType,
		category: expert.mainGroup,
		price: amount,
		brand: expert.name,
		quantity: 1,
	});
	ReactGA.plugin.execute("ec", "setAction", "purchase", {
		id: idProduct,
		revenue: amountWithPromoDiscount,
		coupon: promoCode,
	});
}

const initailDelta = +new Date();

export const initialPagination = {
	currentPage: 0,
	totalPages: 1,
	initial: true,
	isLastPageLoaded: false,
};

export const initialActiveChat = {
	chat: {},
	thread: [],
	pagination: initialPagination,
	delta: initailDelta,
	replyTo: null,
};

export const initialRecents = {
	items: [],
	itemsBySearch: null,
	pagination: initialPagination,
	delta: initailDelta,
};

export const emptyTheadPagination = {
	currentPage: 0,
	totalPages: 0,
	initial: false,
	isLastPageLoaded: true,
};

import React, { Component } from "react";
import PropTypes from "prop-types";
import "./css/pick-list.module.css";

// redux

// libs

// imgs

class PickList extends Component {
	constructor(props) {
		super(props);
		this.selectItem = this.selectItem.bind(this);

		this.state = {
			selectedItemsID: this.props.selected,
		};
	}

	selectItem(id) {
		try {
			let selectedItemsID = this.state.selectedItemsID;
			if (this.props.type === "radio") {
				// pick only 1 item
				selectedItemsID = id;
			} else {
				// pick multiple items
				if (selectedItemsID.indexOf(id) === -1) {
					selectedItemsID.push(id);
				} else {
					selectedItemsID.splice(selectedItemsID.indexOf(id), 1);
				}
			}
			this.setState({
				selectedItemsID: selectedItemsID,
			});
			this.props.onChange(selectedItemsID);
		} catch (error) {}
	}

	componentDidUpdate() {
		if (this.props.selected !== this.state.selectedItemsID) {
			this.setState({
				selectedItemsID: this.props.selected,
			});
		}
	}

	render() {
		const { childrenStyleName } = this.props;

		return (
			<ul
				styleName={
					childrenStyleName ? childrenStyleName + " pick-list" : "pick-list"
				}
				className="flex-col"
			>
				{this.props.fullList.map((list) => (
					<li
						styleName="item"
						key={list.id}
						style={this.props.padding ? { padding: this.props.padding } : null}
					>
						<div styleName="content" className="flex-col">
							<div className="title-5">{list.title}</div>
							<div className="caption c_base60">{list.subtitle}</div>
						</div>
						<div
							className={
								this.state.selectedItemsID.indexOf(list.id) > -1
									? "round-checkbox_selected"
									: "round-checkbox"
							}
							onClick={() => this.selectItem(list.id)}
						></div>
					</li>
				))}
			</ul>
		);
	}
}

// todo: provide array type of selected field where picklist is used
PickList.propTypes = {
	fullList: PropTypes.array.isRequired,
	// selected: PropTypes.array.isRequired,
	childrenStyleName: PropTypes.string,
};

export default PickList;

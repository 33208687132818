import i18n from "i18n";
const t = i18n.t.bind(i18n);

export function subscriptionEventAboutLoc(product) {
	const { availableToAllPaid, validSubscriptions } = product;
	if (availableToAllPaid) return t("SubscribersOnlyPost.Title");
	else if (Array.isArray(validSubscriptions) && validSubscriptions.length) {
		const namesLabel = validSubscriptions.reduce((acc, cur, index) => {
			const accStr = index === 0 ? "" : `${acc}, `;
			return cur.name ? accStr + cur.name : acc;
		}, "");
		return `${t("AccesibleSubPlans.Title")}${namesLabel}`;
	} else return t("PublicPost.Title");
}

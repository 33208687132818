import React from "react";

function Icon(props) {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M22 19.9999V12.1739C22.0001 11.6294 21.889 11.0907 21.6735 10.5906C21.458 10.0906 21.1428 9.63982 20.747 9.26591L13.374 2.29791C13.0027 1.94683 12.511 1.75122 12 1.75122C11.489 1.75122 10.9973 1.94683 10.626 2.29791L3.253 9.26591C2.85722 9.63982 2.54195 10.0906 2.3265 10.5906C2.11104 11.0907 1.99994 11.6294 2 12.1739V19.9999C2 20.5303 2.21071 21.039 2.58579 21.4141C2.96086 21.7892 3.46957 21.9999 4 21.9999H20C20.5304 21.9999 21.0391 21.7892 21.4142 21.4141C21.7893 21.039 22 20.5303 22 19.9999Z"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Icon;

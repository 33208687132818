import React from "react";

const urlify = function (text, options) {
	try {
		const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
		const parts = text.split(urlRegex);
		for (let i = 1; i < parts.length; i += 2) {
			const link = getClickableLink(parts[i]);
			parts[i] = (
				<a
					href={link}
					target="_blank"
					rel="noopener noreferrer"
					className="link_inherit urlified"
					key={i}
				>
					{parts[i]}
				</a>
			);
		}
		return parts;
	} catch (error) {
		return text;
	}
};

function getClickableLink(link) {
	return link.startsWith("http://") || link.startsWith("https://")
		? link
		: `http://${link}`;
}

export default urlify;

import { createSlice } from "@reduxjs/toolkit";
import { uniqueArrayOfObjByKey } from "functions";
import { API } from "functions";
import i18n from "i18n";
import update from "immutability-helper";
import { store } from "store";

// Slice
const slice = createSlice({
	name: "feed",
	initialState: {
		posts: [],
		pagination: {
			currentPage: 0,
			totalPages: 1,
			initial: true,
		},
		activeCategory: { idCategory: "ALL", name: i18n.t("Explore.Group.All") },
		feedOwnerId: "",
	},
	reducers: {
		updateFeedPosts: (state, action) => {
			state.posts = action.payload;
		},
		storeNewPostsToFeed: (state, action) => {
			state.posts = action.payload.posts;
			state.pagination = action.payload.pagination;
			state.feedOwnerId = action.payload.feedOwnerId;
		},
		updateActiveCategory: (state, action) => {
			state.activeCategory = action.payload;
		},
		deleteFeedSuccess: (state) => {
			state.posts = [];
			state.pagination = {
				currentPage: 0,
				totalPages: 1,
				initial: true,
			};
			state.activeCategory = {
				idCategory: "ALL",
				name: i18n.t("Explore.Group.All"),
			};
		},
	},
});
export default slice.reducer;

// Actions
const {
	updateFeedPosts,
	storeNewPostsToFeed,
	deleteFeedSuccess,
	updateActiveCategory,
} = slice.actions;

export const deleteFeed = () => async (dispatch) => {
	try {
		dispatch(deleteFeedSuccess());
	} catch (e) {
		return console.error(e);
	}
};

export const togglePostLike = (idPost, isLiked) => async (
	dispatch,
	getState
) => {
	const { posts, selectedPost, postIndex } = dispatch(getPostsData(idPost));

	const updatedPosts = update(posts, {
		[postIndex]: {
			isLikedBySelf: { $set: isLiked },
			totalLikes: {
				$set: isLiked
					? selectedPost.totalLikes + 1
					: selectedPost.totalLikes - 1,
			},
		},
	});

	dispatch(updateFeedPosts(updatedPosts));
};

export const storeCommentToPost = (newComment) => async (
	dispatch,
	getState
) => {
	try {
		const { posts, postIndex } = dispatch(getPostsData(newComment.idPost));

		const updatedPosts = postsWithNewPostComments(posts, postIndex, [
			newComment,
		]);
		updatedPosts[postIndex].totalResponses += 1;

		dispatch(updateFeedPosts(updatedPosts));
	} catch (error) {
		console.log(error);
	}
};

export const storeSubCommentToPost = (newComment) => async (
	dispatch,
	getState
) => {
	try {
		const { posts, selectedPost, postIndex } = dispatch(
			getPostsData(newComment.idPost)
		);

		const commentIndex = selectedPost.latestComments.findIndex((comment) => {
			return comment.idComment === newComment.idParentComment;
		});

		const isSubCommentsExists = Array.isArray(
			selectedPost.latestComments[commentIndex].comments
		);

		const updatedPosts = isSubCommentsExists
			? update(posts, {
					[postIndex]: {
						latestComments: {
							[commentIndex]: { comments: { $unshift: [newComment] } },
						},
					},
			  })
			: update(posts, {
					[postIndex]: {
						latestComments: {
							[commentIndex]: { comments: { $set: [newComment] } },
						},
					},
			  });

		dispatch(updateFeedPosts(updatedPosts));
	} catch (error) {
		console.log(error);
	}
};

export const loadComments = (idPost) => async (dispatch, getState) => {
	try {
		const { selectedPost, posts, postIndex } = dispatch(getPostsData(idPost));
		const lastCommentPage = selectedPost.lastCommentPage || 0;
		const pageSize = 3;

		const data = {
			page: lastCommentPage,
			size: pageSize,
		};
		const response = await API.post(`feed/get-comments/${idPost}`, data);
		const { items: newComments, totalPages } = response.data;

		const updatedPosts = postsWithNewPostComments(
			posts,
			postIndex,
			newComments
		);
		const newLastCommentPage = lastCommentPage + 1;
		updatedPosts[postIndex].lastCommentPage = newLastCommentPage;
		updatedPosts[postIndex].hideLoadMoreCommentsBtn =
			newLastCommentPage >= totalPages;

		dispatch(updateFeedPosts(updatedPosts));
	} catch (error) {
		console.log(error);
	}
};

export const deletePost = (idPost) => async (dispatch, getState) => {
	try {
		const {
			feed: { posts },
		} = getState();

		const newPosts = posts.filter((post) => post.idPost !== idPost);
		dispatch(updateFeedPosts(newPosts));
	} catch (error) {
		console.log(error);
	}
};

export const deleteComment = async (comment) => {
	try {
		await API.delete(`feed/remove/COMMENT/${comment.idComment}`);
		const { posts, selectedPost, postIndex } = store.dispatch(
			getPostsData(comment.idPost)
		);
		const newComments = selectedPost.latestComments.filter(
			(com) => com.idComment !== comment.idComment
		);
		const newPosts = update(posts, {
			[postIndex]: {
				latestComments: { $set: newComments },
			},
		});
		store.dispatch(updateFeedPosts(newPosts));
	} catch (error) {
		console.log(error);
	}
};

export const deleteSubComment = async (comment, idParentComment) => {
	try {
		await API.delete(`feed/remove/COMMENT/${comment.idComment}`);
		const { posts, selectedPost, postIndex } = store.dispatch(
			getPostsData(comment.idPost)
		);

		const commentIndex = selectedPost.latestComments.findIndex((com) => {
			return com.idComment === idParentComment;
		});

		const newSubcomments = selectedPost.latestComments[
			commentIndex
		].comments.filter((com) => {
			return com.idComment !== comment.idComment;
		});

		const newPosts = update(posts, {
			[postIndex]: {
				latestComments: {
					[commentIndex]: { comments: { $set: newSubcomments } },
				},
			},
		});

		store.dispatch(updateFeedPosts(newPosts));
	} catch (error) {
		console.log(error);
	}
};

export const unshiftNewPosts = (newPost) => async (dispatch, getState) => {
	try {
		const {
			feed: { posts },
		} = getState();

		const mergedPosts = [newPost, ...posts];
		const uniquePosts = uniqueArrayOfObjByKey(mergedPosts, "idPost");
		dispatch(updateFeedPosts(uniquePosts));
	} catch (error) {
		console.log(error);
	}
};

export const replacePost = (newPost) => async (dispatch, getState) => {
	try {
		const {
			feed: { posts },
		} = getState();

		const postsToUpdate = [...posts];
		const postIndex = posts.findIndex((post) => post.idPost === newPost.idPost);
		postsToUpdate[postIndex] = newPost;
		dispatch(updateFeedPosts(postsToUpdate));
	} catch (error) {
		console.log(error);
	}
};

export const storeNewPosts = ({
	items: newPosts,
	totalPages = 1,
	updatePagination = true,
	feedOwnerId,
}) => async (dispatch, getState) => {
	try {
		const {
			feed: {
				posts,
				pagination,
				pagination: { currentPage },
			},
		} = getState();

		const mergedPosts = [...posts, ...newPosts];
		const uniquePosts = uniqueArrayOfObjByKey(mergedPosts, "idPost");

		const updatedPagination = updatePagination
			? {
					currentPage: currentPage + 1,
					totalPages: totalPages,
			  }
			: pagination;

		dispatch(
			storeNewPostsToFeed({
				posts: uniquePosts,
				pagination: updatedPagination,
				feedOwnerId: feedOwnerId,
			})
		);
	} catch (error) {
		console.log(error);
	}
};

export const rewritePosts = ({
	items: newPosts,
	totalPages = 1,
	feedOwnerId,
}) => async (dispatch, getState) => {
	try {
		const data = {
			posts: newPosts,
			pagination: {
				currentPage: 1,
				totalPages: totalPages,
			},
			feedOwnerId,
		};

		dispatch(storeNewPostsToFeed(data));
	} catch (error) {
		console.log(error);
	}
};

export const changeActiveCategory = (category) =>
	store.dispatch(updateActiveCategory(category));

const postsWithNewPostComments = (posts, postIndex, newComments) => {
	const selectedPost = posts[postIndex];
	const updatedComments = updatePostComments(selectedPost, newComments);

	const updatedPosts = update(posts, {
		[postIndex]: { latestComments: { $set: updatedComments } },
	});
	return updatedPosts;
};

// update, filter & sort comments in provided post
const updatePostComments = (selectedPost, newComments) => {
	const storedComments = selectedPost.latestComments;
	const mergedComments = storedComments
		? [...storedComments, ...newComments]
		: newComments;
	const uniqueComments = uniqueArrayOfObjByKey(mergedComments, "idComment");
	const sortedComments = uniqueComments.sort((a, b) => b.created - a.created);
	return sortedComments;
};

const getPostsData = (idPost) => (dispatch, getState) => {
	const {
		feed: { posts },
	} = getState();
	const postIndex = posts.findIndex((post) => post.idPost === idPost);
	const selectedPost = posts[postIndex];
	return { postIndex, posts, selectedPost };
};

export { updateFeedPosts };

import React from "react";
import "./css/notifications.module.css";

// imgs
import NOTIF_IMG from "imgs/notifications/Notification.png";

// libs
import { useTranslation } from "react-i18next";

export default function EmptyState() {
	const { t } = useTranslation();

	return (
		<div styleName="empty-state">
			<img src={NOTIF_IMG} className="mb_24 fw" alt="notifications" />
			<div className="title-5">{t("Notifications.Empty.Text")}</div>
		</div>
	);
}

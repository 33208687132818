import React from "react";
import "./css/header.module.css";

// redux
import { useSelector } from "react-redux";

// components
import { NotificationsBtn } from "./notifications";
import LogoLink from "./LogoLink";
import ExploreLink from "./ExploreLink";
import ChatLink from "./ChatLink";
import OrdersLink from "./OrdersLink";
import NavDropdown from "./dropdown/NavDropdown";

export default function LoggedInNav(props) {
	const { user } = props;
	const { eventsCounter, chatCounter, notificationsCounter } = useSelector(
		(state) => state.newEventsCount
	);

	return (
		<nav styleName="nav">
			<LogoLink />
			<ul styleName="nav-list">
				<ExploreLink />
				<ChatLink counter={chatCounter} />
				<OrdersLink counter={eventsCounter} />
				<NotificationsBtn counter={notificationsCounter} />
				<NavDropdown user={user} />
			</ul>
		</nav>
	);
}

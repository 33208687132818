import i18n from "i18n";
const t = i18n.t.bind(i18n);

const errorCodeTexts = {
	1001: t("Core.Error.NoSuchUser.Text"),
	1002: t("Core.Error.SomethingWrong.Text"),
	// 1003: t("emptyRequestValue"),
	1004: t("Core.Error.EmailAlreadyExist.Text"),
	1005: t("Error.MissedSocToken"),
	// 1006: t("invalidAccessToken"),
	// 1007: t("invalidEmailOrPassword"),
	1009: t("Core.Error.EmailIsInvalid2.Text"),
	1008: t("Core.Error.MandatoryFieldIsNull.Text"),
	1010: t("Core.Error.SocialAccountAlreadyExist.Text"),
	1018: t("Core.Error.DepositAlreadyExist.Text"),
	1019: t("Core.Error.WithdrawAlreadyExist.Text"),
	1021: t("Core.Error.TransactionAlreadyApproved.Text"),
	1022: t("Core.Error.YouBlockedSelf.Text"),
	1023: t("Core.Error.UserBlockedSelf.Text"),
	1024: t("Core.Error.CallInProgress.Text"),
	1025: t("Core.Error.NotEnoughMoney.Text"),
	1028: t("Core.Error.IncompleteRequest"),
	1029: t("Core.Error.WrongChecksum.Text"),
	1030: t("Core.Error.UserBlockedByAdmin.Text"),
	// 1031: t("stripe"),
	1033: t("Core.Error.UserBlockedByAdmin.Text"),
	1034: t("Core.Error.PartnerDisabledCalls.Text"),
	1035: t("Core.Error.PartnerDisabledMessages.Text"),
	1036: t("Error.NameAndType.AlreadyExist"),
	1037: t("Core.Error.WrongAppVersion"),
	1039: t("Core.Error.IncompleteRequest"),
	1040: t("Forum.Error.ServiceWillBeAvailable"),
	1042: t("Core.Error.Such.Content.Already.Exist"),
	1043: t("Profile.Nickname.Validate.Text"),
	1046: t("UploadVideo.Error.FileSize"),
	1047: t("UploadVideo.Error.BadInternet"),
	1048: t("Error.Product.Everything_Sold"),
	1050: t("Chats.Disabled.File.Sending.Error"),
	1051: t("Auction.WrongBid.Error"),
	1052: t("Auction.HasEnded.Error"),
	1053: t("Auction.AuctionInProgress.Error"),
	// 1054: t("canNotCreateAuction"),
	1057: t("Error.InvalidPhoneNumber"),
	1058: t("Error.PageIsNotFound"),
	1059: t("Error.PostWasRemoved"),
	1060: t("Error.MaxCheckAttemptsReached"),
	1061: t("Error.MaxSendAttemptsReached"),
	1062: t("Error.VerificationCodeHasExpired"),
	1063: t("PermissionForDeleteContent.Title"),
	1064: t("ActivityRestricted.Title"),
	1065: t("UserAlreadyBlocked.Title"),
	1066: t("SubscriptionAlreadyActive.Error"),
	1067: t("CanNotEditDate.error"),
	// 1068: t(""),
	1069: t("Notification.NotImplemented"),
	1070: t("Code.NO_MESSAGES_LEFT"),
	// 1071: t(""),
	1072: t("NO_ACTIVE_PRODUCT"),
	// 1073: t(""),
	1074: t("REMOVED_FROM_CHAT"),
	1075: t("ANONYM_USER"),
	21005: t("Core.IOS.InAppError.ServerIsDown"),
	// 21100...21199: t("receiptInternalError"),
	// 21003...21010: t("receiptNotAuthed"),
};

export default errorCodeTexts;

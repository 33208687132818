import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";

export default function Dropdown(props) {
	const {
		icon = null,
		btnContent = null,
		btnProps = {},
		minWidth = "240px",
		children,
	} = props;
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			{btnContent ? (
				<button
					className="btn_trans"
					aria-label="more"
					aria-controls="more-btn"
					aria-haspopup="true"
					onClick={handleClick}
					{...btnProps}
				>
					{btnContent}
				</button>
			) : (
				<IconButton
					aria-label="more"
					aria-controls="more-btn"
					aria-haspopup="true"
					onClick={handleClick}
				>
					{icon || <DefaultBtnIcon />}
				</IconButton>
			)}
			<Menu
				// id="more-btn"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				onClick={handleClose}
				getContentAnchorEl={null}
				style={{ minWidth: minWidth }}
			>
				{children}
			</Menu>
		</>
	);
}

const DefaultBtnIcon = () => <MoreVertIcon />;

const DropdownItem = React.forwardRef((props, ref) => (
	<MenuItem {...props} innerRef={ref} />
));

export { DropdownItem };

import React, { useEffect, useState } from "react";
import "./css/notifications.module.css";

// utils
import { API } from "functions";

// libs
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";

// components
import NotificationsFactory from "./NotificationsFactory";
import AutoPagination from "./AutoPagination";
import EmptyState from "./EmptyState";

const Notifications = React.forwardRef(({ onClose }, ref) => {
	const [items, setItems] = useState([]);
	const [pagination, setPagination] = useState({
		currentPage: 0,
		totalPages: 1,
	});
	const [isFetching, setisFetching] = useState(true);
	const { t } = useTranslation();

	useEffect(() => {
		getNotifications();
	}, []);

	const getNotifications = async () => {
		try {
			const data = {
				page: pagination.currentPage,
				size: 5,
			};
			const response = await API.post(`event-logs/${true}`, data);
			if (response.status === 200) {
				setItems([...items, ...response.data.items]);
				setPagination({
					currentPage: pagination.currentPage + 1,
					totalPages: response.data.totalPages,
				});
			}
		} catch (error) {
			console.log(error);
		}
		if (isFetching) setisFetching(false);
	};

	return (
		<div styleName="notifications-container">
			<div styleName="title" className="title-5">
				{t("Settings.Item.Notifications.Title")}
			</div>
			{isFetching ? (
				<div styleName="loading-container">
					<CircularProgress size={24} />
				</div>
			) : (
				<div styleName="notifications-list">
					{items.length > 0 ? (
						<>
							{items.map((item) => (
								<NotificationsFactory
									key={item.id}
									item={item}
									onClose={onClose}
								/>
							))}
							<AutoPagination
								loadNewPage={getNotifications}
								isVisible={
									items.length > 0 &&
									pagination.currentPage < pagination.totalPages
								}
							/>
						</>
					) : (
						<EmptyState />
					)}
				</div>
			)}
		</div>
	);
});

export default Notifications;

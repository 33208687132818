import { createSlice } from "@reduxjs/toolkit";
import { API } from "functions";
import { store } from "store";
import i18n from "i18n";
import update from "immutability-helper";

const t = i18n.t.bind(i18n);
// const allGroup = {
// 	idGroup: "all",
// 	name: "all",
// 	localizedString: t("Explore.Group.All"),
// };
const allActiveGroup = {
	groupName: "all",
	categoryName: null,
	localizedString: t("Explore.Group.All"),
};
const itialGroups = []; // allGroup
const initialState = {
	groups: itialGroups,
	activeGroup: allActiveGroup,
	initial: true,
};
// const initialState = {
// 	groups: itialGroups,
// 	activeGroup: {},
// 	initial: true,
// };

// Slice
const slice = createSlice({
	name: "explore",
	initialState: initialState,
	reducers: {
		storeGroups: (state, action) => {
			state.groups = action.payload.groups;
			state.initial = action.payload.initial;
		},
		storeActiveGroup: (state, action) => {
			state.activeGroup = action.payload;
		},
	},
});
export default slice.reducer;

// Actions
const { storeGroups, storeActiveGroup } = slice.actions;

export const exploreInit = () => {
	const {
		explore: { groups },
	} = store.getState();
	groups.length <= 1 ? getGroups() : initialGroupSelect();
};

export const getGroups = async () => {
	try {
		const response = await API.get(`get-groups/$lang/false`);
		const sortedGroups = response.data.sort((a, b) => a.order - b.order);
		// const groups = [...itialGroups, ...sortedGroups];
		const groups = [...sortedGroups];
		store.dispatch(storeGroups({ groups, initial: true }));
		const fallbackGroup = groups[0];
		initialGroupSelect(fallbackGroup);
	} catch (e) {
		return console.error(e);
	}
};

export const changeActiveGroup = (group) => {
	store.dispatch(storeActiveGroup(group));
	const { users, category } = getFullGroupInfo(group);
	if (!!!users?.length) {
		category ? getUsersByCategory(group) : getUsersByGroup(group);
	}
};

export const getUsersByGroup = async ({ groupName }) => {
	try {
		const { groups, group, groupIndex } = getFullGroupInfo({ groupName });
		const page = group?.currentPage || 0;
		const data = {
			page: page,
			size: 16,
		};
		const {
			data: { items: newUsers, totalPages },
		} = await API.post(`find-users-by-group-name/${groupName}`, data);
		const updUsers = group.users ? [...group.users, ...newUsers] : newUsers;
		const updGroups = update(groups, {
			[groupIndex]: {
				users: { $set: updUsers },
				currentPage: { $set: page + 1 },
				isLastPageLoaded: { $set: page + 1 >= totalPages },
			},
		});
		store.dispatch(storeGroups({ groups: updGroups, initial: false }));
	} catch (e) {
		return console.error(e);
	}
};

export const getUsersByCategory = async ({ groupName, categoryName }) => {
	try {
		const { groups, groupIndex, categoryIndex, category } = getFullGroupInfo({
			groupName,
			categoryName,
		});
		const page = category.currentPage || 0;
		const data = {
			page: page,
			size: 16,
		};
		const {
			data: { items: newUsers, totalPages },
		} = await API.post(
			`find-users-by-group-and-category/${groupName}/${categoryName}`,
			data
		);
		const updUsers = category.users
			? [...category.users, ...newUsers]
			: newUsers;
		const updGroups = update(groups, {
			[groupIndex]: {
				categories: {
					[categoryIndex]: {
						users: { $set: updUsers },
						currentPage: { $set: page + 1 },
						isLastPageLoaded: { $set: page + 1 >= totalPages },
					},
				},
			},
		});
		store.dispatch(storeGroups({ groups: updGroups, initial: false }));
	} catch (e) {
		return console.error(e);
	}
};

export const getFullGroupInfo = ({ groupName, categoryName }) => {
	const {
		explore: { groups },
	} = store.getState();
	const groupIndex = groups.findIndex((group) => group.name === groupName);
	const group = groups[groupIndex];
	const users = group?.users;
	const isPaginationActive = !group?.isLastPageLoaded;
	let result = {
		groups,
		group,
		groupIndex,
		users,
		isPaginationActive,
	};

	if (!!categoryName) {
		const categories = group.categories;
		const categoryIndex = categories.findIndex(
			(category) => category.name === categoryName
		);
		const category = categories[categoryIndex];
		const users = category.users;
		const isPaginationActive = !category.isLastPageLoaded;
		result = {
			...result,
			categories,
			categoryIndex,
			category,
			users,
			isPaginationActive,
		};
	}

	return result;
};

function initialGroupSelect(initialGroup) {
	const query = new URLSearchParams(window.location.search);
	const URLgroup = query.get("group");
	const URLcategory = query.get("category");

	// default logic with all group
	// if (!(URLgroup || URLcategory) || URLgroup === "all") {
	// 	changeActiveGroup(allActiveGroup);
	// }
	// temp logic with hidden "All" group
	if (initialGroup) {
		changeActiveGroup({
			groupName: initialGroup.name,
			localizedString: initialGroup.localizedString,
		});
	} else if (URLcategory) {
		const { category } = getFullGroupInfo({
			groupName: URLgroup,
			categoryName: URLcategory,
		});
		changeActiveGroup({
			groupName: URLgroup,
			categoryName: category.name,
			localizedString: category.localizedString,
		});
	} else if (URLgroup) {
		const { group } = getFullGroupInfo({ groupName: URLgroup });
		changeActiveGroup({
			groupName: group.name,
			localizedString: group.localizedString,
		});
	}
}

import { dataURLtoFile } from "functions";

function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
	const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
	const width = (srcWidth * ratio).toFixed();
	const height = (srcHeight * ratio).toFixed();
	return { width: width, height: height };
}

const resizeImage = (file, options, callback) => {
	var reader = new FileReader();
	reader.onload = function (e) {
		var image = new Image();
		image.src = e.target.result;
		image.onload = function () {
			const { height: originalHeight, width: originalWidth } = this;
			const { maxHeight, maxWidth, max } = options;

			const { width, height } = calculateAspectRatioFit(
				originalWidth,
				originalHeight,
				maxWidth || max,
				maxHeight || max
			);

			const canvas = document.createElement("canvas");
			canvas.width = width;
			canvas.height = height;

			const ctx = canvas.getContext("2d");

			ctx.drawImage(image, 0, 0, width, height);

			const dataURL = canvas.toDataURL(file.type, 0.8);
			const convertedFile = dataURLtoFile(dataURL, file.name);

			URL.revokeObjectURL(image.src);
			callback(convertedFile);
		};
	};

	reader.readAsDataURL(file);
};

const resizeImageAsync = (file, options) => {
	return new Promise((resolve) => {
		resizeImage(file, options, (resizedFile) => {
			resolve(resizedFile);
		});
	});
};

export { resizeImage, resizeImageAsync };

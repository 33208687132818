import { changeActiveCategory as storeActiveCategory } from "components/common/feed/FeedSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function useFeedCategories() {
	const { club } = useSelector((state) => state.profile);
	const { activeCategory } = useSelector((state) => state.feed);

	const { t } = useTranslation();
	const categories =
		Array.isArray(club?.feedCategories) && club?.feedCategories.length > 0
			? [
					{
						idCategory: "ALL",
						name: t("Explore.Group.All"),
					},
					...club.feedCategories,
			  ]
			: null;

	const changeActiveCategory = (category) => storeActiveCategory(category);

	return { categories, changeActiveCategory, activeCategory };
}

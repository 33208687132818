import React from "react";

// libs
import i18n from "i18n";
import { Trans } from "react-i18next";
import { FacebookShareButton } from "react-share";

// imgs
//instructions
import FORM_ICON from "imgs/purchase form/instructions/1.svg";
import CARD_ICON from "imgs/purchase form/instructions/2.svg";
import PHONE_ICON from "imgs/purchase form/instructions/3.svg";
import CAMERA_ICON from "imgs/purchase form/instructions/4.svg";
import TADA_ICON from "imgs/purchase form/instructions/5.svg";
//thank you page
import THP_ICON1 from "imgs/product/thankyou-page-1.svg";
import THP_ICON2 from "imgs/product/thankyou-page-2.svg";
import THP_ICON3 from "imgs/product/thankyou-page-3.svg";
import THP_HEART_ICON from "imgs/purchase form/TYP/heart.svg";
import THP_SHARE_BTN_EN_ICON from "imgs/purchase form/TYP/share_button_en.svg";
import THP_SHARE_BTN_RU_ICON from "imgs/purchase form/TYP/share_button_ru.svg";
import THP_SHARE_BTN_UK_ICON from "imgs/purchase form/TYP/share_button_ua.svg";
import { Link } from "react-router-dom";

export function getProductLocalizedTitle(product) {
	switch (product.productType) {
		case "AUTOGRAPH": {
			return product.name || i18n.t("Product.Autograph.Cover.Title");
		}
		case "VIDEO_GREETING": {
			return product.name || i18n.t("Product.VG.Cover.Title");
		}
		case "AUTO_REPLY": {
			return product.name || i18n.t("Product.Url.Title");
		}
		case "PHYSICAL": {
			return product.name || i18n.t("Product.Physical.Cover.Title");
		}
		case "EVENT": {
			return product.name || i18n.t("Product.Event.Cover.Title");
		}
		case "COLLABORATION": {
			return product.name || i18n.t("Product.Collaboration.Cover.Title");
		}
		case "DONATION": {
			return product.name || i18n.t("MakeAGift.Text");
		}
		case "SUBSCRIPTION": {
			return product.name || i18n.t("Exclusive.Subscription.Title");
		}
		case "MSG": {
			return i18n.t("Common.Message");
		}
		case "CALL": {
			return i18n.t("Common.VideoCall");
		}
		case "MESSAGE_PACKAGE": {
			return i18n.t("MessagesBundle.Title");
		}
		case "CALL_PACKAGE": {
			return i18n.t("CallsBundle.Title");
		}
		default:
			return null;
	}
}

export function getProductDescription(product) {
	if (product.shortAbout) return product.shortAbout;
	else if (product.about) return product.about;
	return getProductFormDescription(product);
}

export function getProductFormDescription(product) {
	if (product.about) return product.about;
	switch (product.productType) {
		case "AUTOGRAPH": {
			return i18n.t("Autograph.AboutProduct.Default.Text");
		}
		case "VIDEO_GREETING": {
			return i18n.t("VideoGreeting.AboutProduct.Default.Text");
		}
		case "AUTO_REPLY": {
			return i18n.t("AutoReplay.AboutProduct.Default.Text");
		}
		case "EVENT": {
			return i18n.t("Event.Default.Description");
		}
		case "COLLABORATION": {
			return i18n.t("Collaboration.Default.Description");
		}
		case "PHYSICAL": {
			return i18n.t("Physical.Default.Description");
		}
		case "CALL_PACKAGE":
			return i18n.t("ChooseCallsBundle.Title", {
				name: product.ownerName,
			});
		case "MESSAGE_PACKAGE":
			return i18n.t("ChooseApplicableMessagePack.Title", {
				name: product.ownerName,
			});
		default:
			return "";
	}
}

export function getProductInstructions(product) {
	switch (product.productType) {
		case "AUTOGRAPH":
			return [
				<>
					<img src={FORM_ICON} alt="1" />
					{i18n.t("Common.Product.Instruction.Item1")}
				</>,
				<>
					<img src={CARD_ICON} alt="2" />
					{i18n.t("Common.Product.Instruction.Item2")}
				</>,
				<>
					<img src={PHONE_ICON} alt="3" />
					{i18n.t("Autograph.Instruction.Item3")}
				</>,
				<div className="caption">
					{i18n.t("AutographAndVideoGreeting.Comment")}
				</div>,
			];
		case "VIDEO_GREETING":
			return [
				<>
					<img src={FORM_ICON} alt="1" />
					{i18n.t("Common.Product.Instruction.Item1")}
				</>,
				<>
					<img src={CARD_ICON} alt="2" />
					{i18n.t("Common.Product.Instruction.Item2")}
				</>,
				<>
					<img src={PHONE_ICON} alt="3" />
					{i18n.t("VideoGreeting.Instruction.Item3")}
				</>,
				<>
					<img src={CAMERA_ICON} alt="4" />
					{i18n.t("VideoGreeting.Instruction.Item4")}
				</>,
				<div className="caption">
					{i18n.t("AutographAndVideoGreeting.Comment")}
				</div>,
			];
		case "PHYSICAL":
			return [
				<>
					<img src={FORM_ICON} alt="1" />
					{i18n.t("Common.Product.Instruction.Item1")}
				</>,
				<>
					<img src={CARD_ICON} alt="2" />
					{i18n.t("Physical.Instruction.Item2")}
				</>,
				<>
					<img src={PHONE_ICON} alt="3" />
					{i18n.t("Physical.Instruction.Item3")}
				</>,
			];
		case "COLLABORATION":
			return [
				<>
					<img src={FORM_ICON} alt="1" />
					{i18n.t("Common.Product.Instruction.Item1")}
				</>,
				<>
					<img src={CARD_ICON} alt="2" />
					{i18n.t("Collaboration.Instruction.Item2")}
				</>,
				<>
					<img src={PHONE_ICON} alt="3" />
					{i18n.t("Collaboration.Instruction.Item3")}
				</>,
			];
		case "EVENT":
			if (
				product.idOwner === "5e02530121e59d56c21c0a74" ||
				product.idOwner === "5db9fb29cdb85511d0dae658"
			) {
				return [
					<>
						<img src={FORM_ICON} alt="1" />
						{i18n.t("Product.PurchaseForm.Instructions.Klichko.Label1.Web")}
					</>,
					<>
						<img src={CARD_ICON} alt="2" />
						{i18n.t("Product.PurchaseForm.Instructions.Klichko.Label2.Web")}
					</>,
					<>
						<img src={PHONE_ICON} alt="3" />
						{i18n.t("Product.PurchaseForm.Instructions.Klichko.Label3.Web")}
					</>,
					<>
						<img src={CAMERA_ICON} alt="4" />
						{i18n.t("Product.PurchaseForm.Instructions.Klichko.Label4.Web")}
					</>,
					<>
						<img src={TADA_ICON} alt="5" />
						{i18n.t("Product.PurchaseForm.Instructions.Klichko.Label5.Web")}
					</>,
				];
			}
			return [
				<>
					<img src={FORM_ICON} alt="1" />
					{i18n.t("Common.Product.Instruction.Item1")}
				</>,
				<>
					<img src={CARD_ICON} alt="2" />
					{i18n.t("Event.Instruction.Item2")}
				</>,
				<>
					<img src={PHONE_ICON} alt="3" />
					{i18n.t("Event.Instruction.Item3")}
				</>,
				<>
					<img src={CAMERA_ICON} alt="3" />
					{i18n.t("Event.Instruction.Item4")}
				</>,
			];
		case "CALL_PACKAGE":
		case "MESSAGE_PACKAGE":
			return null;
		default:
			return [
				<>
					<img src={FORM_ICON} alt="1" />
					{i18n.t("Common.Product.Instruction.Item1")}
				</>,
				<>
					<img src={CARD_ICON} alt="2" />
					{i18n.t("AutoReplay.Instruction.Item2")}
				</>,
				<>
					<img src={PHONE_ICON} alt="3" />
					{i18n.t("AutoReplay.Instruction.Item3")}
				</>,
			];
	}
}

export function getMessageInstructions(params) {
	return [
		<>
			<img src={FORM_ICON} alt="1" />
			{i18n.t("Common.Product.Instruction.Item1")}
		</>,
		<>
			<img src={CARD_ICON} alt="2" />
			{i18n.t("Message.Instruction.Item2")}
		</>,
		<>
			<img src={PHONE_ICON} alt="3" />
			{i18n.t("Message.Instruction.Item3")}
		</>,
		<div className="caption">{i18n.t("NewMessage.Comment")}</div>,
	];
}

// --------- Purchase form ---------- //
export function digitalWalletLocalize() {
	const userAgent = navigator.userAgent || navigator.vendor || window.opera;
	if (/android/i.test(userAgent)) return "Google Pay";
	else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
		return "Apple Pay";
	else return i18n.t("Use.Digital.Wallet.Text");
}

export function hiddenCheckboxText(product) {
	if (product?.productType === "VIDEO_GREETING")
		return i18n.t("Product.Buy.Table.KeepHidden.VG.Text.Web");
	else if (product?.productType === "AUTOGRAPH") {
		if (
			product.idOwner === "5e02530121e59d56c21c0a74" ||
			product.idOwner === "5db9fb29cdb85511d0dae658"
		)
			return i18n.t("Product.PurchaseForm.KeepHidden.Checkbox.Web");
		return i18n.t("Product.Buy.Table.KeepHidden.Autograph.Text.Web");
	} else return i18n.t("Product.Buy.Table.KeepHidden.Text");
}

export function errorLocalizeTitle(reason, product) {
	switch (reason) {
		case "invalid-fields":
			if (product?.productType === "AUTO_REPLY")
				return i18n.t(
					"Product.PurchaseForm.MandatoryFieldIsNull.Title.AutoReply.Web"
				);
			else return i18n.t("Product.PurchaseForm.MandatoryFieldIsNull.Title.Web");
		default:
			return i18n.t("Core.Error.Title");
	}
}

export function errorLocalizeText(reason, state) {
	const { message, product } = state;

	switch (reason) {
		case "rejected":
			return i18n.t("Product.PurchaseForm.Payment.CardRejected.Web");
		case "not-enough-funds":
			return i18n.t("Core.Error.NotEnoughMoney.Text");
		case "invalid-fields":
			if (message)
				return i18n.t("Mesage.PurchaseForm.MandatoryFieldIsNull.Web");
			else if (product.productType === "VIDEO_GREETING")
				return i18n.t("Product.PurchaseForm.MandatoryFieldIsNull.VG.Web");
			else if (product.productType === "AUTOGRAPH")
				return i18n.t(
					"Product.PurchaseForm.MandatoryFieldIsNull.Autograph.Web"
				);
			else if (product.productType === "AUTO_REPLY")
				return i18n.t(
					"Product.PurchaseForm.MandatoryFieldIsNull.AutoReply.Web"
				);
			else if (product.productType === "EVENT")
				return i18n.t("Product.PurchaseForm.MandatoryFieldIsNull.Event.Web");
			else if (product.productType === "COLLABORATION")
				return i18n.t(
					"Product.PurchaseForm.MandatoryFieldIsNull.Collaboration.Web"
				);
			else if (product.productType === "PHYSICAL")
				return i18n.t("Product.PurchaseForm.MandatoryFieldIsNull.Physical.Web");
			else return i18n.t("Core.Error.MandatoryFieldIsNull.Text");
		case 1048:
			return i18n.t("Error.Product.Everything_Sold");
		case 1045:
			return i18n.t("Settings.PromoCode.InvalidMessage");
		case 1023:
			return i18n.t("Profile.BlockedUser.Description");
		case 1022:
			return i18n.t("Core.Error.YouBlockedSelf.Text");
		default:
			return i18n.t("Product.PurchaseForm.Payment.CardRejected.Web");
	}
}

export function getRecipientHintLocalize(product) {
	switch (product.productType) {
		case "AUTOGRAPH":
			if (
				product.idOwner === "5e02530121e59d56c21c0a74" ||
				product.idOwner === "5db9fb29cdb85511d0dae658"
			)
				return null;
			return i18n.t("Product.Buy.TextField.ForUserName.Details.Autograph.Web");
		case "VIDEO_GREETING":
			return i18n.t("Product.Buy.TextField.ForUserName.Details.VG.Web");
		default:
			return "";
	}
}

export function getTHPcontent(config, onLinkCliked) {
	const { productType, user, additional = {} } = config;
	let content = [];
	let shareBtn = ((lang) => {
		switch (lang) {
			case "ru":
				return THP_SHARE_BTN_RU_ICON;
			case "uk":
				return THP_SHARE_BTN_UK_ICON;
			default:
				return THP_SHARE_BTN_EN_ICON;
		}
	})(i18n.languages[0]);

	switch (productType) {
		case "MSG": {
			content = [
				{
					key: 1,
					icon: THP_ICON1,
					text: i18n.t("ThankYouPage.Message.ExistUser.DoesNotLogin.Item1"),
				},
				{
					key: 2,
					icon: THP_ICON2,
					text: i18n.t("ThankYouPage.Message.ExistUser.DoesNotLogin.Item2"),
				},
			];
			break;
		}
		case "AUTOGRAPH": {
			content = [
				{
					key: 1,
					icon: THP_ICON1,
					text: i18n.t("Autograph.ThankYouPage.Item1"),
				},
				{
					key: 2,
					icon: THP_ICON2,
					text: i18n.t("Profile.ThankYouPage.Caption2"),
				},
			];
			break;
		}
		case "VIDEO_GREETING": {
			content = [
				{
					key: 1,
					icon: THP_ICON1,
					text: i18n.t("Profile.ThankYouPage.Caption1"),
				},
				{
					key: 2,
					icon: THP_ICON2,
					text: i18n.t("Profile.ThankYouPage.Caption2"),
				},
			];
			break;
		}
		case "EVENT": {
			content = [
				{
					key: 1,
					icon: THP_ICON1,
					text: (
						<Trans i18nKey="Event.ThankYouPage.Item1">
							Go to
							<span className="link" onClick={() => onLinkCliked("/orders")}>
								chat
							</span>
							or
						</Trans>
					),
				},
			];
			break;
		}
		case "CH-COLLABORATION":
		case "COLLABORATION": {
			content = [
				{
					key: 1,
					icon: THP_ICON1,
					text: (
						<Trans i18nKey="Collaboration.ThankYouPage.Item1">
							Go to
							<span className="link" onClick={() => onLinkCliked("/orders")}>
								chat
							</span>
							or
						</Trans>
					),
				},
			];
			break;
		}
		case "PHYSICAL": {
			content = [
				{
					key: 1,
					icon: THP_ICON1,
					text: (
						<Trans i18nKey="Physical.ThankYouPage.Item2">
							Go to
							<span className="link" onClick={() => onLinkCliked("/orders")}>
								chat
							</span>
							or
						</Trans>
					),
				},
			];
			break;
		}
		case "AUTO_REPLY": {
			content = [
				{
					key: 1,
					icon: THP_ICON1,
					text: (
						<Trans i18nKey="AutoReplay.ThankYouPage.Item1">
							Go to
							<span className="link" onClick={() => onLinkCliked("/orders")}>
								chat
							</span>
							or
						</Trans>
					),
				},
			];
			break;
		}
		case "CH-VIDEO_GREETING": {
			content = [
				{
					key: 1,
					icon: THP_HEART_ICON,
					text: (
						<span style={{ whiteSpace: "pre-line" }}>
							{i18n.t("TYP.Charity.Item1")}
						</span>
					),
				},
				{
					key: 2,
					icon: null,
					text: (
						<div onClick={() => onLinkCliked("FacebookShareButton")}>
							<FacebookShareButton
								className="typ_share-btn"
								url="https://payphone.io"
							>
								<img src={shareBtn} alt="" />
							</FacebookShareButton>
						</div>
					),
				},
				{
					key: 3,
					icon: THP_ICON1,
					text: i18n.t("TYP.VG.Charity.Item2"),
				},
			];
			break;
		}
		case "CH-AUTOGRAPH": {
			content = [
				{
					key: 1,
					icon: THP_HEART_ICON,
					text: (
						<div onClick={() => onLinkCliked("FacebookShareButton")}>
							<span style={{ whiteSpace: "pre-line" }}>
								{i18n.t("TYP.Charity.Item1")}
							</span>
						</div>
					),
				},
				{
					key: 2,
					icon: null,
					text: (
						<div onClick={() => onLinkCliked("FacebookShareButton")}>
							<FacebookShareButton
								className="typ_share-btn"
								url="https://payphone.io"
							>
								<img src={shareBtn} alt="" />
							</FacebookShareButton>
						</div>
					),
				},
				{
					key: 3,
					icon: THP_ICON1,
					text: i18n.t("TYP.Autograph.Charity.Item2"),
				},
			];
			break;
		}
		case "CH-PHYSICAL": {
			content = [
				{
					key: 1,
					icon: THP_HEART_ICON,
					text: (
						<div onClick={() => onLinkCliked("FacebookShareButton")}>
							<span style={{ whiteSpace: "pre-line" }}>
								{i18n.t("TYP.Charity.Item1")}
							</span>
						</div>
					),
				},
				{
					key: 2,
					icon: null,
					text: (
						<div onClick={() => onLinkCliked("FacebookShareButton")}>
							<FacebookShareButton
								className="typ_share-btn"
								url="https://payphone.io"
							>
								<img src={shareBtn} alt="" />
							</FacebookShareButton>
						</div>
					),
				},
				{
					key: 3,
					icon: THP_ICON1,
					text: i18n.t("TYP.Physical.Charity.Item2"),
				},
			];
			break;
		}
		case "CH-EVENT": {
			content = [
				{
					key: 1,
					icon: THP_HEART_ICON,
					text: (
						<span style={{ whiteSpace: "pre-line" }}>
							{i18n.t("TYP.Charity.Item1")}
						</span>
					),
				},
				{
					key: 2,
					icon: null,
					text: (
						<div onClick={() => onLinkCliked("FacebookShareButton")}>
							<FacebookShareButton
								className="typ_share-btn"
								url="https://payphone.io"
							>
								<img src={shareBtn} alt="" />
							</FacebookShareButton>
						</div>
					),
				},
				{
					key: 3,
					icon: THP_ICON1,
					text: i18n.t("TYP.Event.Charity.Item2"),
				},
			];
			break;
		}
		case "CH-AUTO_REPLY": {
			content = [
				{
					key: 1,
					icon: THP_HEART_ICON,
					text: (
						<span style={{ whiteSpace: "pre-line" }}>
							{i18n.t("TYP.Charity.Item1")}
						</span>
					),
				},
				{
					key: 2,
					icon: null,
					text: (
						<div onClick={() => onLinkCliked("FacebookShareButton")}>
							<FacebookShareButton
								className="typ_share-btn"
								url="https://payphone.io"
							>
								<img src={shareBtn} alt="" />
							</FacebookShareButton>
						</div>
					),
				},
				{
					key: 3,
					icon: THP_ICON1,
					text: i18n.t("TYP.AutoReply.Charity.Item2"),
				},
			];
			break;
		}
		case "CH-MSG": {
			content = [
				{
					key: 1,
					icon: THP_HEART_ICON,
					text: (
						<span style={{ whiteSpace: "pre-line" }}>
							{i18n.t("TYP.Charity.Item1")}
						</span>
					),
				},
				{
					key: 2,
					icon: null,
					text: (
						<div onClick={() => onLinkCliked("FacebookShareButton")}>
							<FacebookShareButton
								className="typ_share-btn"
								url="https://payphone.io"
							>
								<img src={shareBtn} alt="" />
							</FacebookShareButton>
						</div>
					),
				},
				{
					key: 3,
					icon: THP_ICON1,
					text: i18n.t("TYP.Msg.Charity.Item2"),
				},
			];
			break;
		}
		case "SUBSCRIPTION": {
			const { ownerName: name, ownerId } = additional;
			content = [
				{
					key: 1,
					icon: THP_ICON1,
					text: (
						<Trans i18nKey="SubscriptionSuccessfullyActivated.Text">
							Now you have access to premium content, which {{ name }} will
							prepare personally for you. By the way, check other interesting
							offers on the talent's profile. Take a look now and choose
							something you will like.
							<span
								className="link"
								onClick={() => onLinkCliked(`/${ownerId}`)}
							>
								Go to profile
							</span>
						</Trans>
					),
				},
			];
			break;
		}
		case "DONATION": {
			const { ownerId } = additional;
			content = [
				{
					key: 1,
					icon: THP_ICON1,
					text: i18n.t("Donation.Accepted"),
				},
			];
			if (user !== "exlo") {
				content.push({
					key: 2,
					icon: THP_ICON3,
					text: (
						<Link to={`/${ownerId}`} className="label-btn">
							{i18n.t("GoToProfile.Button")}
						</Link>
					),
				});
			}
			break;
		}
		case "MESSAGE_PACKAGE": {
			const { ownerName: name, packageAmount: amount } = additional;
			content = [
				{
					key: 1,
					icon: THP_ICON1,
					text: i18n.t("SuccessfullyPurchasedMessagesBundle.Item1", {
						name,
						amount,
					}),
				},
				{
					key: 2,
					icon: THP_ICON2,
					text: i18n.t("SuccessfullyPurchasedMessagesBundle.Item2"),
				},
				{
					key: 3,
					icon: THP_ICON3,
					text: (
						<Link to={`/chat`} className="label-btn">
							{i18n.t("StartTheConveersation.Button")}
						</Link>
					),
				},
			];
			break;
		}
		case "CALL_PACKAGE": {
			const { ownerName: name, packageAmount: amount } = additional;
			content = [
				{
					key: 1,
					icon: THP_ICON1,
					text: i18n.t("SuccessfullyPurchasedCallBundle.Item1", {
						name,
						amount,
					}),
				},
				{
					key: 2,
					icon: THP_ICON2,
					text: i18n.t("SuccessfullyPurchasedMessagesBundle.Item2"),
				},
				{
					key: 3,
					icon: THP_ICON3,
					text: (
						<Link to={`/orders`} className="label-btn">
							{i18n.t("StartTheConveersation.Button")}
						</Link>
					),
				},
			];
			break;
		}
		default:
			break;
	}

	switch (user) {
		case "new":
			if (productType === "DONATION") break;
			const { idChat } = additional;

			if (idChat) {
				content.push({
					key: "user_1",
					icon: THP_ICON3,
					text: (
						<Trans i18nKey="ThankYouPage.ExistUser.Login.Item2">
							Go to
							<span
								className="link"
								onClick={() => onLinkCliked(`/chat/${idChat}`)}
							>
								chat
							</span>
							or visit our
							<span className="link" onClick={() => onLinkCliked("/explore")}>
								catalog
							</span>
							to talk to famous people!
						</Trans>
					),
				});
			} else
				content.push({
					key: "user_1",
					icon: THP_ICON3,
					text: (
						<Trans i18nKey="ThankYouPage.NewUser.Item2">
							Right now though is a good time to complete
							<span className="link" onClick={() => onLinkCliked("/settings")}>
								your profile
							</span>
							on Payphone X – this way it’ll be easier to communicate with
							opinion leaders, well-known bloggers and celebrities from our
							<span className="link" onClick={() => onLinkCliked("/explore")}>
								catalog
							</span>
							.
						</Trans>
					),
				});
			break;
		case "exlo": // exists logged out
			content.push({
				key: "user_1",
				icon: THP_ICON3,
				text: (
					<Trans i18nKey="ThankYouPage.ExistUser.DoesNotLogin.Item2">
						To keep talking to famous people, feel free to
						<span className="link" onClick={() => onLinkCliked("/login")}>
							log in
						</span>
						to your account here or follow the link sent to you by email.
					</Trans>
				),
			});
			break;
		case "exli": {
			// exists logged in
			if (
				productType === "DONATION" ||
				productType === "MESSAGE_PACKAGE" ||
				productType === "CALL_PACKAGE"
			)
				break;
			const { idChat } = additional;
			const chatLink = idChat ? `/chat/${idChat}` : "/orders";
			content.push({
				key: "user_1",
				icon: THP_ICON3,
				text: (
					<Trans i18nKey="ThankYouPage.ExistUser.Login.Item2">
						Go to
						<span className="link" onClick={() => onLinkCliked(chatLink)}>
							chat
						</span>
						or visit our
						<span className="link" onClick={() => onLinkCliked("/explore")}>
							catalog
						</span>
						to talk to famous people!
					</Trans>
				),
			});
			break;
		}
		default:
			break;
	}

	return content;
}

export function phoneNumberLabel(productType) {
	switch (productType) {
		case "AUTOGRAPH":
		case "VIDEO_GREETING":
		case "AUTO_REPLY":
			return i18n.t("Product.PurchaseForm.YourPhone.Optional.Label.Web");
		case "EVENT":
		case "COLLABORATION":
		case "PHYSICAL":
			return i18n.t("Product.PurchaseForm.YourPhone.Label.Web");
		default:
			// msg & sub is undefind here
			return i18n.t("Product.PurchaseForm.YourPhone.Optional.Label.Web");
	}
}

export function phoneNumberHint(productType) {
	switch (productType) {
		case "AUTOGRAPH":
		case "VIDEO_GREETING":
		case "AUTO_REPLY":
			return i18n.t("Product.PurchaseForm.YourPhone.Optional.Invalid.Web");
		case "EVENT":
		case "COLLABORATION":
		case "PHYSICAL":
			return i18n.t("Product.PurchaseForm.YourPhone.Invalid.Web");
		default:
			return "";
	}
}

import { createSlice } from "@reduxjs/toolkit";
import { store } from "store";
import { initialRecents, initialActiveChat } from "./initial.constants";

// Slice
const slice = createSlice({
	name: "chat",
	initialState: {
		storedChats: {},
		recents: initialRecents,
		activeChat: initialActiveChat,
		wsEvent: null,
	},
	reducers: {
		storeActiveChat: (state, action) => {
			state.activeChat = action.payload;
		},
		storeRecents: (state, action) => {
			state.recents = action.payload;
		},
		saveStoredChats: (state, action) => {
			state.storedChats = action.payload;
		},
		storeWSEvent: (state, action) => {
			state.wsEvent = action.payload;
		},
	},
});

export default slice.reducer;
export const {
	storeActiveChat,
	storeRecents,
	saveStoredChats,
	storeWSEvent,
} = slice.actions;

export const storeWSChatEvent = (event) => store.dispatch(storeWSEvent(event));

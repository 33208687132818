import React from "react";
import { NavLink } from "react-router-dom";
import "./css/header.module.css";

// imgs
import { ReactComponent as ExploreIcon } from "imgs/header/explore.svg";

export default function ExploreLink() {
	return (
		<li styleName="nav-item">
			<NavLink to="/explore" styleName="nav-link" activeClassName="active-link">
				<ExploreIcon className="stroke-icon icon_xs" />
			</NavLink>
		</li>
	);
}

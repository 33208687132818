import React from "react";
import "./css/notifications.module.css";

// libs
import { CircularProgress } from "@material-ui/core";
import InView from "react-intersection-observer";

export default function AutoPagination(props) {
	const { loadNewPage, isVisible } = props;

	const inViewHandle = (inView) => {
		if (inView) {
			loadNewPage();
		}
	};

	return isVisible ? (
		<div styleName="pagination-autoload-container">
			<InView
				as="div"
				styleName="pagination-autoload-flag"
				onChange={(inView) => inViewHandle(inView)}
			/>
			<CircularProgress className="cir-prog_white" size={24} />
		</div>
	) : null;
}

import React from "react";
import "./css/header.module.css";

// components
import LogoLink from "./LogoLink";
import ExploreLink from "./ExploreLink";
import LoginLink from "./LoginLink";
import LangBtn from "./LangBtn";

export default function LoggedOutNav() {
	return (
		<nav styleName="nav">
			<LogoLink />
			<ul styleName="nav-list">
				<LangBtn />
				<ExploreLink />
				<LoginLink />
			</ul>
		</nav>
	);
}

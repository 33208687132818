import React from "react";
import "./css/tile-picker.module.css";

export default function TileBtn(props) {
	const {
		id,
		isActive,
		title,
		subtitle,
		caption,
		price,
		priceCaption,
		input = null,
		onClick,
	} = props;

	return (
		<button
			styleName={isActive ? "product-rate-btn-active" : "product-rate-btn"}
			className="mr_16"
			onClick={() => onClick(id)}
		>
			<div className="flex-col">
				<div styleName="title-with-picker" className="wrap-text">
					<div className="title-5">{title}</div>
					<div className="caption c_base60">{subtitle}</div>
				</div>
				{caption && (
					<span className="text-1 c_base60 mt_16 pre-wrap">{caption}</span>
				)}
				{input && input}
			</div>
			<div styleName="price-col">
				{price && <div className="title-5">{price}</div>}
				{priceCaption && <div className="caption c_base60">{priceCaption}</div>}
			</div>
		</button>
	);
}

function pixelEvent(event, data) {
	if (window.fbq) {
		const fbq = window.fbq;
		fbq("track", event, data);
	}
}

export function pixelPurchaseEvent(state = {}) {
	const {
		product: { productType } = {},
		expert: { name, idUser } = {},
	} = state;
	const data = {
		content_ids: idUser,
		content_name: name,
		content_type: productType,
	};
	pixelEvent("Purchase", data);
}

export function pixelPurshaseFormOpen(state = {}) {
	const { productType = "MESSAGE", idProduct = "" } = state;
	const data = {
		content_ids: idProduct,
		content_category: productType,
	};
	pixelEvent("InitiateCheckout", data);
}

export function pixelCatalogSearch(term) {
	const data = {
		search_string: term,
	};
	pixelEvent("Search", data);
}

export function pixelCompleteRegistration(name) {
	const data = {
		content_name: name,
	};
	pixelEvent("CompleteRegistration", data);
}

export function pixelProfileOpened(state = {}) {
	const { products = [], userDTO: { name, idUser } = {}, groups = [] } = state;
	const content_type = products
		.map((product) => product.productType)
		.toString();
	const content_category =
		Array.isArray(groups) && groups.length ? groups[0].name : "";
	const data = {
		content_ids: idUser,
		content_category: content_category,
		content_name: name,
		content_type: content_type,
	};
	pixelEvent("ViewContent", data);
}

import { useState, useEffect } from "react";

// Options:
// fetchFn (required): the function to execute to get data
// loadOnMount (opt): load the data on component mount
// clearDataOnLoad (opt): clear old data on new load regardless of success state
// usage
// const { response, isLoading, error, request } = useAsyncData({ fetchFn: (event) => fetchFn(event) });

const useAsyncData = ({
	loadOnMount = false,
	clearDataOnLoad = false,
	fetchFn = null,
} = {}) => {
	// data fetching state variables
	const [response, setResponse] = useState();
	const [error, setError] = useState();
	const [isLoading, setIsLoading] = useState(false);

	// A function to handle all the data fetching logic
	const request = async (event) => {
		setIsLoading(true);
		setError();
		if (clearDataOnLoad === true) setResponse();

		try {
			const resp = await fetchFn(event);
			setResponse(resp);
			setIsLoading(false);
		} catch (e) {
			setError(e);
			setIsLoading(false);
		}
	};

	// 'onMount'
	// maybe load the data if required
	useEffect(() => {
		if (loadOnMount && fetchFn !== null) request();
	}, []);

	// Return the state and the load function to the component
	return { response, isLoading, error, request };
};
export default useAsyncData;

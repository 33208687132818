export default function momentLocaleImporter(lang) {
	switch (lang) {
		case "ru":
			import("moment/locale/ru").then();
			break;
		case "uk":
			import("moment/locale/uk").then();
			break;
		default:
			break;
	}
}

import React from "react";
import "./css/modal.module.css";
// libs
import { useTranslation } from "react-i18next";

// components
import Modal from "./Modal";
import AppLang from "components/pages/settings/lang & currency/AppLang";

export default function LangsModal(props) {
	const { texts, closeModal, isOpen, onSumbit } = props;
	const { t } = useTranslation();

	return (
		<Modal
			maxWidth="500px"
			borderRadius="20px"
			isOpen={isOpen}
			closeModal={closeModal}
			title={t("Settings.AppLanguage.Title.Web")}
			texts={texts}
		>
			<div>
				<AppLang isTitleHidden={true} />
				<div styleName="modal-btns">
					<button className="pr-btn_prim fw" onClick={onSumbit}>
						{t("Common.Continue")}
					</button>
				</div>
			</div>
		</Modal>
	);
}

import React, { Component } from "react";
import {
	aTokenInLocalstorage,
	checkCountry,
	getCurrencies,
	updateUser,
	isPagePreventUserSync,
} from "functions";
import "./styles/styles.css";
import userSyncing from "functions/ws/userSyncing";

// redux
import * as storeUserInfo from "redux/actions/UserAction";
import { getEventsCount } from "redux/ducks/newEventsCount";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// components
import Header from "components/common/header/Header";
import CookiesModal from "components/common/modal/CookiesModal";
import ReduxModal from "components/common/modal/ReduxModal";

class App extends Component {
	componentDidMount() {
		this.userWSSubscrption = null;
		this.isLoggedUser(
			(loggedOut) => {
				this.storeDefaultAToken();
			},
			(loggenIn) => {
				updateUser();
				this.props.getEventsCount();
			}
		);

		checkCountry(() => getCurrencies());
	}

	componentDidUpdate(prevProps) {
		const currentUserId = this.props.user?.user?.idUser;
		const prevUserId = prevProps.user?.user?.idUser;
		if (!!currentUserId && currentUserId !== prevUserId) {
			userSyncing(currentUserId, (subscrption) => {
				this.userWSSubscrption = subscrption;
			});
		}
	}

	componentWillUnmount() {
		if (this.userWSSubscrption) this.userWSSubscrption.unsubscribe();
	}

	storeDefaultAToken() {
		localStorage.setItem(
			"aToken",
			JSON.stringify(process.env.REACT_APP_DEFAULT_BEARER)
		);
	}

	isLoggedUser(newUserCallback, loggedInUserCallback) {
		const aToken = JSON.parse(localStorage.getItem("aToken"));
		const defaultBearer = process.env.REACT_APP_DEFAULT_BEARER;
		if (!aTokenInLocalstorage()) {
			newUserCallback();
		} else if (isPagePreventUserSync()) {
			// prevent user syncing in pages that expect new bearer in path
		} else if (aToken !== defaultBearer) {
			loggedInUserCallback();
		}
	}

	render() {
		const { user } = this.props;

		return (
			<div className="App">
				<Header user={user} location={this.props.location} />
				<CookiesModal />
				<ReduxModal />
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user,
		newEventsCount: state.newEventsCount,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		storeUserInfo: bindActionCreators(storeUserInfo, dispatch),
		getEventsCount: bindActionCreators(getEventsCount, dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

import React, { Component } from "react";
import "./css/dropdown-static.module.css";

class DropdownStatic extends Component {
	constructor(props) {
		super(props);
		this.closeMenu = this.closeMenu.bind(this);
	}

	componentDidUpdate(prevProps) {
		const { isOpen } = this.props;
		if (prevProps.isOpen !== isOpen && isOpen)
			document.addEventListener("click", this.closeMenu);
	}

	closeMenu(event) {
		this.props.onClose();
		document.removeEventListener("click", this.closeMenu);
	}

	styleName() {
		const { position } = this.props;
		let styleName = "dropdown";
		if (position) styleName += ` dropdown_${position}`;
		return styleName;
	}

	render() {
		const { isOpen, children, style } = this.props;
		const styleName = this.styleName();

		return (
			isOpen && (
				<div
					className="dropdown"
					styleName={styleName}
					style={style}
					ref={(element) => {
						this.dropdownMenu = element;
					}}
				>
					{children}
				</div>
			)
		);
	}
}

export default DropdownStatic;

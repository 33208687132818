import { STORE_USER } from "../constants/list";

const initialState = {
	user: "",
};

export default function user(state = initialState, action) {
	switch (action.type) {
		case STORE_USER:
			return { ...state, user: action.payload };

		default:
			return state;
	}
}

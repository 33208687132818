import { currencyRate } from "functions";

export default function priceLabels() {
	const selectedCurrency = JSON.parse(localStorage.getItem("currency"))
		?.selectedCurrency?.name;
	const isPriceCaptionHidden = selectedCurrency === "USD";
	const priceLabel = (sum) => (!!!sum || isNaN(sum) ? null : `$${sum}`);
	const inputPriceCaptionLabel = (sum) =>
		!!!sum || isNaN(sum) || isPriceCaptionHidden
			? null
			: ` ~${currencyRate(sum)}`;

	return {
		priceLabel,
		inputPriceCaptionLabel,
	};
}

import React from "react";
import { Link } from "react-router-dom";
import "./css/header.module.css";

// imgs
import LOGO_IMG from "imgs/logos/pp-logo.svg";
import TYPE_IMG from "imgs/logos/pp-type.svg";

export default function LogoLink() {
	return (
		<Link className="nav-link flex_centred__h" to="/">
			<img src={LOGO_IMG} alt="logo" className="mr_8" />
			<img styleName="logotype-img" src={TYPE_IMG} alt="payphonex" />
		</Link>
	);
}

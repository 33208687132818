import { AUTH_TOKEN } from '../constants/list';

const initialState = {
  authToken: ''
}

export default function authToken(state = initialState, action) {
  switch (action.type) {
    case AUTH_TOKEN:
      return { ...state, authToken: action.payload }

    default:
      return state;
  }
}

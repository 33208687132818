import { md5 } from "functions";
import { store } from "store";
import { getEventsCount } from "redux/ducks/newEventsCount";
import Fingerprint2 from "fingerprintjs2";
import updateUser from "functions/update-user";
import stompConnection from "./stompConnection";
import { updateNotificationCounter } from "redux/ducks/newEventsCount";
import { storeWSChatEvent } from "components/pages/chat/slice/chat.slice";

export default function userSyncing(idUser, onSubscribe) {
	generateDeviceId((deviceID) => {
		userSocket(deviceID, idUser, onSubscribe);
	});
}

function handleSocketMessage(message) {
	const body = JSON.parse(message.body);
	body.forEach((event) => {
		switch (event.eventType) {
			case "SELF_USER_UPDATED":
				updateUser(event.userDTO);
				break;
			case "WALLET_UPDATED":
				updateUser(event.userDTO);
				break;
			case "THREAD_UPDATED":
				store.dispatch(getEventsCount());
				store.dispatch({
					type: "WS_EVENT",
					payload: body,
				});
				break;
			case "CHAT_UPDATED":
				storeWSChatEvent(event);
				break;
			case "EVENT_LOG_UPDATED":
				updateNotificationCounter(event.eventLogs);
				break;
			default:
				break;
		}
	});
}

function generateDeviceId(callback) {
	Fingerprint2.get((components) => {
		const d = new Date();
		const t = d.getTime();
		components.push({ key: "timestamp", value: t });
		let values = components.map(function (component) {
			return component.value;
		});
		const murmur = Fingerprint2.x64hash128(values.join(""), 31);
		const md5Murmur = md5(murmur);
		callback(md5Murmur);
	});
}

function userSocket(deviceID, idUser, onSubscribe) {
	stompConnection(
		{
			headers: {
				authorization: "Bearer " + JSON.parse(localStorage.getItem("aToken")),
				device: deviceID,
			},
			link: `/queue/${idUser}/${deviceID}`,
			sync: true,
			useDelta: true,
		},
		{
			newMessage: (message) => handleSocketMessage(message),
			onSubscribe: (subscription) => {
				onSubscribe(subscription);
			},
		}
	);
}

import { store } from "store";

function logoutUser() {
	store.dispatch({
		type: "STORE_USER",
		payload: null,
	}); // redux
	// reset bearer to default if present was failed
	localStorage.setItem(
		"aToken",
		JSON.stringify(process.env.REACT_APP_DEFAULT_BEARER)
	);

	window.location.href = "/login";
}

export default logoutUser;

import { createSlice } from "@reduxjs/toolkit";
import { API } from "functions";
import { store } from "store";

// Slice
const slice = createSlice({
	name: "newEventsCount",
	initialState: { eventsCounter: 0, notificationsCounter: 0, chatCounter: 0 },
	reducers: {
		getEventsCountSuccess: (state, action) => {
			state.eventsCounter = action.payload.eventsCounter;
			state.notificationsCounter = action.payload.notificationsCounter;
			state.chatCounter = action.payload.chatCounter;
		},
		storeNotificationSuccess: (state, action) => {
			state.notificationsCounter = action.payload;
		},
	},
});
export default slice.reducer;
// Actions
const { getEventsCountSuccess, storeNotificationSuccess } = slice.actions;
export const getEventsCount = () => async (dispatch) => {
	try {
		const response = await API.get("new-events-count");
		dispatch(
			getEventsCountSuccess({
				eventsCounter: response.data.result,
				notificationsCounter: response.data.eventLogs,
				chatCounter: response.data.chats,
			})
		);
	} catch (e) {
		return console.error(e);
	}
};

export const updateNotificationCounter = async (counter) => {
	try {
		store.dispatch(storeNotificationSuccess(counter));
	} catch (e) {
		return console.error(e);
	}
};

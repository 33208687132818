import { errorCodeTexts } from "./errorCodes";

// redux
import { openErrorSnackbar } from "redux/ducks/modal";

function displayError(error, config) {
	const errorCode = error?.error;
	const errorMsg = errorCodeTexts[errorCode];

	if (errorMsg) {
		openErrorSnackbar({
			text: errorCodeTexts[errorCode],
		});
	} else if (config?.showDefaultError) openErrorSnackbar();
}

export default displayError;

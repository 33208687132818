import React from "react";

// utils
import { priceLabels, paymentTypes } from "./utils";

// libs
import { useTranslation } from "react-i18next";

// components
import { TileBtn } from "components/common/tile picker";

export default function DonationTypes(props) {
	const { price, activeType, onTypeChange } = props;
	const { t } = useTranslation();
	const { priceLabel, inputPriceCaptionLabel } = priceLabels();
	const types = paymentTypes(t);

	return (
		<>
			{types.map((type) => {
				const { id, title, caption } = type;
				return (
					<TileBtn
						key={id}
						id={id}
						isActive={activeType === id}
						title={title}
						caption={activeType === id ? caption : null}
						price={priceLabel(price)}
						priceCaption={inputPriceCaptionLabel(price)}
						onClick={onTypeChange}
					/>
				);
			})}
		</>
	);
}

import i18n from "i18n";
import currencyRate from "./currencyRate";
const t = i18n.t.bind(i18n);

export default function servicePriceLabel(type, price) {
	if (price === null) return null;
	if (price === 0) return t("Profile.Price.Free.Label");

	price = currencyRate(price);
	switch (type) {
		case "PRODUCT":
			return price;
		case "MESSAGE":
			return `${price}/${t("Common.Message").toLowerCase()}`;
		case "CALL":
			return `${price}/${t("Thread.Item.Call.Duration.Min.Web")}`;
		default:
			return "";
	}
}

export function productPriceLabel(product) {
	const productType = product?.productType;

	if (productType === "MESSAGE_PACKAGE")
		return lowestPackagePrice(product.variations);
	else if (productType === "CALL_PACKAGE") {
		return lowestCallPackagePrice(product.variations);
	}

	return servicePriceLabel("PRODUCT", product.standardRate);
}

const lowestPackagePrice = (variations) => {
	if (Array.isArray(variations) && variations.length) {
		const minPriceInterval = variations.reduce((prev, curr) =>
			prev.price < curr.price ? prev : curr
		);
		const price = currencyRate(Number(minPriceInterval.price).toFixed(2));
		const priceLabel =
			variations.length > 1
				? t("Subscription.From.Month", {
						price,
				  })
				: t("Subscription.Per.Month", { price });
		return priceLabel;
	}
	return "";
};

const lowestCallPackagePrice = (variations) => {
	if (Array.isArray(variations) && variations.length) {
		const minPriceInterval = variations.reduce((prev, curr) =>
			prev.price < curr.price ? prev : curr
		);
		const price = currencyRate(Number(minPriceInterval.price).toFixed(2));
		const priceLabel = `${t("Product.PurchaseForm.Header.From.Web")} ${price}`;
		return priceLabel;
	}
	return "";
};

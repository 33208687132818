import { WS_EVENT } from '../constants/list';

const initialState = {
  wsEvent: null
}

export default function wsEvent(state = initialState, action) {
  switch (action.type) {
    case WS_EVENT:
      return { ...state, wsEvent: action.payload }

    default:
      return state;
  }
}


import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import "./css/modal-wrapper.module.css";

//components
import ModalHeader from "./ModalHeader";
import ModalContent from "./ModalContent";

// libs
import { withTranslation } from "react-i18next";
import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock";

const modalRoot = document.getElementById("modal-root");

class Modal extends Component {
	constructor(props) {
		super(props);
		this.lockBodyScroll = this.lockBodyScroll.bind(this);
		this.modalClick = this.modalClick.bind(this);
		this.modalRef = React.createRef();
		this.targetElement = this.modalRef.current;
	}

	componentDidMount() {
		this.lockBodyScroll(this.props.isOpen);
	}

	componentWillUnmount() {
		this.lockBodyScroll(false);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isOpen !== this.props.isOpen) {
			this.lockBodyScroll(this.props.isOpen);
		}
	}

	modalClick = (event) => {
		if (
			event.target.className.includes("modal-wrapper") &&
			!this.props.ignoreOnClose
		)
			this.props.closeModal();
	};

	escFunction = (event) => {
		if (event.keyCode === 27 && !this.props.ignoreOnClose)
			this.props.closeModal();
	};

	lockBodyScroll(lock) {
		if (lock) {
			this.targetElement = this.modalRef.current;
			if (this.targetElement?.querySelectorAll(".scrollable-content")?.length) {
				// prevent scroll freeze for modal content - ios fix
				let scrollableChild = this.targetElement.querySelectorAll(
					".scrollable-content"
				)[0];
				this.targetElement = scrollableChild;
			}
			disableBodyScroll(this.targetElement);
			document.addEventListener("keydown", this.escFunction, false);
		} else {
			document.removeEventListener("keydown", this.escFunction, false);
			if (this.targetElement) enableBodyScroll(this.targetElement);
		}
	}

	modalStyleName() {
		// to refactor: use loop if it will be more props
		const { noPadding } = this.props;
		let modalStyleName = "modal";
		if (noPadding) modalStyleName += " no-padding";
		return modalStyleName;
	}

	modalWrapperStyleName() {
		// to refactor: use loop if it will be more props
		const { fullScreen, transperent } = this.props;
		let modalWrapperStyleName = "modal-wrapper";
		if (fullScreen) modalWrapperStyleName += " full-screen";
		if (transperent) modalWrapperStyleName += " transperent";

		return modalWrapperStyleName;
	}

	render() {
		const {
			isOpen,
			maxWidth,
			padding,
			borderRadius,
			header,
			texts,
			closeModal,
			title,
			childdrenStyleName,
		} = this.props;

		return isOpen
			? ReactDOM.createPortal(
					<div
						ref={this.modalRef}
						styleName={this.modalWrapperStyleName()}
						onClick={this.modalClick}
						onKeyPress={this.handleKeyPress}
					>
						<div
							styleName={this.modalStyleName()}
							style={{
								maxWidth: maxWidth,
								padding: padding,
								borderRadius: borderRadius,
							}}
						>
							{(header || title) && (
								<ModalHeader
									closeModal={closeModal}
									title={title ? title : header.title}
									style={header?.style ? header.style : null}
								/>
							)}
							{texts && <ModalContent texts={texts} />}
							{childdrenStyleName ? (
								<div styleName={childdrenStyleName}>{this.props.children}</div>
							) : (
								this.props.children
							)}
						</div>
					</div>,
					modalRoot
			  )
			: null;
	}
}

Modal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	noPadding: PropTypes.bool,
	selected: PropTypes.object,
	maxWidth: PropTypes.string,
	texts: PropTypes.array,
	padding: PropTypes.string,
};

export default withTranslation("translations")(Modal);

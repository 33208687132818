import React from "react";
import "./css/page-load.module.css";
import { CircularProgress } from "@material-ui/core";

export default function PageLoad() {
	return (
		<div styleName="page-load">
			<CircularProgress />
		</div>
	);
}

import React from "react";
import "./css/donate-modal.module.css";

// redux
import { useSelector } from "react-redux";

// imgs
import DEFAULT_USER_IMG from "imgs/Default_user_img.png";

// libs
import { useTranslation } from "react-i18next";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

export default function CommentInput(props) {
	const { text, onChange } = props;
	const {
		user: { imageLink },
	} = useSelector((state) => state.user);
	const { t } = useTranslation();

	const authorImg = imageLink || DEFAULT_USER_IMG;

	const handleChange = (ev) => {
		onChange(ev.target.value);
	};

	return (
		<div styleName="comment-input">
			<div>
				<img
					src={authorImg || DEFAULT_USER_IMG}
					alt=""
					className="icon_sm__round"
					onError={(ev) => {
						ev.target.src = DEFAULT_USER_IMG;
					}}
				/>
			</div>
			<div styleName="textarea-container">
				<TextareaAutosize
					rowsMax={8}
					maxLength={500}
					styleName="input"
					placeholder={t("Comment.Input")}
					value={text}
					onChange={handleChange}
				/>
			</div>
		</div>
	);
}

import React, { Component, Suspense, lazy } from "react";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from "react-router-dom";
// import history from "./history"; // to do: use custom history prop??

// libs
import { LastLocationProvider } from "react-router-last-location";

import App from "../App";
import withTracker from "../withTracker";
import PageLoad from "components/common/page load/PageLoad";

const Login = lazy(() => import("components/pages/login/Login"));
const LoginPassword = lazy(() =>
	import("components/pages/login/LoginPassword")
);
const Register = lazy(() => import("components/pages/register/Register"));
const SetPassword = lazy(() => import("components/pages/register/SetPassword"));
const Wallet = lazy(() => import("components/pages/wallet/Wallet"));
const Deposit = lazy(() => import("components/pages/wallet/deposit"));
const Withdraw = lazy(() => import("components/pages/wallet/Withdraw"));
const DepositSuccess = lazy(() =>
	import("components/pages/wallet/deposit/DepositSuccess")
);
const Profile = lazy(() => import("components/pages/profile"));
const BuyProduct = lazy(() =>
	import("components/pages/purchase form/BuyProduct")
);
const Orders = lazy(() => import("components/pages/orders/Chat"));
const Chat = lazy(() => import("components/pages/chat"));
const Explore = lazy(() => import("components/pages/explore/Explore"));
const BuyMessage = lazy(() =>
	import("components/pages/purchase form/BuyMessage")
);
const Home = lazy(() => import("components/pages/home/Home"));
const ChatRedirect = lazy(() => import("components/pages/redirect/Chat"));
const OrdersRedirect = lazy(() => import("components/pages/redirect/Orders"));
const SettingsRedirect = lazy(() =>
	import("components/pages/redirect/Settings")
);
const ProductRedirect = lazy(() => import("components/pages/redirect/Product"));
const RedirectPasswordReset = lazy(() =>
	import("components/pages/redirect/RedirectPasswordReset")
);
const RedirectMagicLink = lazy(() =>
	import("components/pages/redirect/RedirectMagicLink")
);
const RedirectUserProfile = lazy(() =>
	import("components/pages/redirect/RedirectUserProfile")
);
const RedirectToMainScreen = lazy(() =>
	import("components/pages/redirect/RedirectToMainScreen")
);
const StripeExternalCheckout = lazy(() =>
	import("components/pages/redirect/StripeExternalCheckout")
);
const DownloadMobilaApp = lazy(() =>
	import("components/pages/download mob app/DownloadMobilaApp")
);
// to refactor: not common!
const SharedProduct = lazy(() =>
	import("components/common/share product/SharedProduct")
);
const EmailUnsubscribe = lazy(() =>
	import("components/pages/email unsubscribe/UnsubscribeEmail")
);
const ConfirmEmail = lazy(() =>
	import("components/pages/confirm email/ConfirmEmail")
);
const Settings = lazy(() => import("components/pages/settings/Settings"));
const AppLangCurrencySettings = lazy(() =>
	import("components/pages/settings/lang & currency/LangCurrencyPage")
);
const ProductBought = lazy(() =>
	import("components/pages/purchase form/ProductBought")
);
const Auction = lazy(() => import("components/pages/auction"));
const AuctionsCatalog = lazy(() =>
	import("components/pages/home/auction/AuctionsCatalog")
);
const FeedSubscription = lazy(() =>
	import("components/pages/feed subscription")
);
const PostSubscriptions = lazy(() =>
	import("components/pages/feed subscription/subscriptions/PostSubscriptions")
);
const ProductSubscriptions = lazy(() =>
	import(
		"components/pages/feed subscription/subscriptions/product subscriptions"
	)
);
const Post = lazy(() => import("components/pages/post"));
const СommunityPostPage = lazy(() => import("components/pages/community post"));
const AuctionWinner = lazy(() =>
	import("components/pages/purchase form/AuctionWinner")
);
const RedirectAuctionWinner = lazy(() =>
	import("components/pages/redirect/RedirectAuctionWinner")
);
const DonateFailed = lazy(() => import("components/pages/donate/Failed"));

class RoutesList extends Component {
	render() {
		return (
			<div className="app-root">
				<Suspense fallback={<PageLoad />}>
					<Switch>
						<Route path="/" component={App} />
					</Switch>

					<Switch>
						<Route
							exact
							path="/mob-app/password-reset/:token"
							component={RedirectPasswordReset}
						/>
						<Route
							exact
							path="/mob-app/link/:token"
							component={RedirectMagicLink}
						/>
						<Route
							exact
							path="/mob-app/main"
							component={RedirectToMainScreen}
						/>
						<Route exact path="/redirect/chat" component={ChatRedirect} />
						<Route exact path="/redirect/orders" component={OrdersRedirect} />
						<Route
							exact
							path="/redirect/settings"
							component={SettingsRedirect}
						/>
						<Route
							exact
							path="/redirect/auction-winner"
							component={RedirectAuctionWinner}
						/>
						<Route exact path="/redirect/product" component={ProductRedirect} />
						<Route
							exact
							path="/redirect/stripe-checkout"
							component={StripeExternalCheckout}
						/>
						<Route exact path="/password" component={LoginPassword} />
						<Route
							exact
							path="/email-unsubscribe/:token"
							component={EmailUnsubscribe}
						/>
						<Route
							exact
							path="/confirm-email/:token"
							component={ConfirmEmail}
						/>
						<Route
							exact
							path="/confirm-corporate-email/:token"
							component={ConfirmEmail}
						/>
						<Route exact path="/revert-email/:token" component={ConfirmEmail} />
						<Route exact path="/set-password" component={SetPassword} />
						<Route exact path="/login" component={Login} />
						<Route exact path="/register" component={Register} />
						<Route
							exact
							path="/mob-app/user-profile/:id"
							component={RedirectUserProfile}
						/>
						<Route
							exact
							path="/profile/:token"
							component={RedirectUserProfile}
						/>
						<Route
							exact
							path="/download-mob-app"
							component={DownloadMobilaApp}
						/>
						<Route exact path="/wallet/deposit/:aToken" component={Deposit} />
						<Route
							exact
							path="/wallet/deposit-success"
							component={DepositSuccess}
						/>
						<Route exact path="/wallet/withdraw/:token" component={Withdraw} />
						<Route exact path="/wallet" component={Wallet} />
						<Route exact path="/settings" component={Settings} />
						<Route
							exact
							path="/app-language"
							component={AppLangCurrencySettings}
						/>
						<Route exact path="/set-pin" component={Settings} />
						<Route
							exact
							path="/settings/feed-categories"
							component={Settings}
						/>
						<Route exact path="/explore/auctions" component={AuctionsCatalog} />
						<Route exact path="/explore" component={Explore} />
						<Route exact path="/message" component={BuyMessage} />
						<Route exact path="/share/product/:id" component={SharedProduct} />
						<Route exact path="/product" component={BuyProduct} />
						<Route exact path="/auction-winner" component={AuctionWinner} />
						<Route exact path="/auction" component={Auction} />
						<Route exact path="/product-bought" component={ProductBought} />
						<Route exact path="/orders" component={Orders} />
						<Route exact path="/chat" component={Chat} />
						<Route exact path="/chat/:idChat" component={Chat} />
						<Route exact path="/post/:idPost" component={Post} />
						<Route exact path="/donate/failed" component={DonateFailed} />
						<Route
							exact
							path="/community/post/:idPost"
							component={СommunityPostPage}
						/>
						<Route
							exact
							path="/feed-subscription/:idSubscription"
							component={FeedSubscription}
						/>
						<Route
							exact
							path="/feed-subscription/:idSubscription/:aToken"
							component={FeedSubscription}
						/>
						<Route
							exact
							path="/post-subscriptions/:idPost"
							component={PostSubscriptions}
						/>
						<Route
							exact
							path="/product-subscriptions"
							component={ProductSubscriptions}
						/>
						<Route
							exact
							path="/post-subscriptions/:idPost/:aToken"
							component={PostSubscriptions}
						/>
						<Redirect from="/user/:id" to="/:id" />
						<Route
							path="/:id"
							render={(props) => (
								<Profile key={props.match.params.id} {...props} />
							)}
						/>
						<Route exact path="/" component={Home} />
					</Switch>
				</Suspense>
			</div>
		);
	}
}

const Root = (props) => (
	<Router {...props}>
		<LastLocationProvider>
			<Route
				component={withTracker(RoutesList, {
					/* additional attributes */
				})}
			/>
		</LastLocationProvider>
	</Router>
);

export default Root;

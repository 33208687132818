import React from "react";
import { Link } from "react-router-dom";
import "./css/notifications.module.css";

// utils
import { urlify } from "functions";
import { MenuContext } from "./NotificationsBtn";

function Content(props) {
	const { img, title, timestamp, text } = props;

	return (
		<>
			<img src={img} className="icon_md__round" alt="" />
			<div styleName="content">
				{!!title && <div className="text-2">{title}</div>}
				<div className="caption c_base60">{timestamp}</div>
				<div className="text-1 pre-wrap">{urlify(text)}</div>
			</div>
		</>
	);
}

export default function NotificationItem(props) {
	const { link } = props;

	return link ? (
		<MenuContext.Consumer>
			{({ onClose }) => (
				<Link to={link} styleName="notification-item" onClick={onClose}>
					<Content {...props} />
				</Link>
			)}
		</MenuContext.Consumer>
	) : (
		<div styleName="notification-item">
			<Content {...props} />
		</div>
	);
}

import React from "react";

// utils
import { formatDate } from "functions";

// imgs
import CHAT_ICON from "imgs/notifications/Chat.svg";
import AUCTION_ICON from "imgs/notifications/Auction.svg";
import WALLET_ICON from "imgs/notifications/Wallet.svg";
import AUTOGRAPH_ICON from "imgs/notifications/Autograph.svg";
import EVENT_ICON from "imgs/notifications/Event.svg";
import FILE_ICON from "imgs/notifications/File.svg";
import MERCH_ICON from "imgs/notifications/Merch.svg";
import MESSAGE_ICON from "imgs/notifications/Message.svg";
import VG_ICON from "imgs/notifications/Videogreeting.svg";
import COLLAB_ICON from "imgs/notifications/Collaboration.svg";
import GIFT_ICON from "imgs/notifications/Gift.svg";
// import CALL_ICON from "imgs/notifications/Call.svg";

// libs
import { useTranslation } from "react-i18next";

// components
import NotificationItem from "./NotificationItem";

export default function NotificationsFactory(props) {
	const {
		item: {
			createdDate,
			idPartner,
			idPost,
			idProduct,
			idUser,
			partnerImageLink,
			partnerName,
			productName,
			productType,
			sum,
			type,
			text,
		},
	} = props;
	const { t } = useTranslation();
	const formatedDate = formatDate(createdDate);

	switch (type) {
		case "NEW_COMMENT":
			return (
				<NotificationItem
					img={CHAT_ICON}
					link={`/post/${idPost}`}
					timestamp={formatedDate}
					text={t("Inapp.NewComment")}
				/>
			);
		case "NEW_POST":
			return (
				<NotificationItem
					img={partnerImageLink}
					link={`/post/${idPost}`}
					timestamp={formatedDate}
					text={t("Inapp.NewPost", { from: partnerName })}
				/>
			);
		case "NEW_COMMENT_ANSWER":
			return (
				<NotificationItem
					img={CHAT_ICON}
					link={`/post/${idPost}`}
					timestamp={formatedDate}
					text={t("Inapp.NewReply")}
				/>
			);
		case "MISS_YOU_POST":
			return (
				<NotificationItem
					img={CHAT_ICON}
					link={`/${idUser}`}
					timestamp={formatedDate}
					text={t("Inapp.HaventPostLongTime")}
				/>
			);
		case "POPULAR_POST":
			return (
				<NotificationItem
					img={CHAT_ICON}
					link={`/post/${idPost}`}
					timestamp={formatedDate}
					text={t("Inapp.Over100Comments")}
				/>
			);
		case "AUCTION_STARTED_FOR_OWNER":
			return (
				<NotificationItem
					img={AUCTION_ICON}
					link={`/product?id=${idProduct}&user=${idUser}`}
					title={productName}
					timestamp={formatedDate}
					text={t("Notification.Auction.StartedForOwner")}
				/>
			);
		case "FIRST_BID":
			return (
				<NotificationItem
					img={AUCTION_ICON}
					link={`/product?id=${idProduct}&user=${idUser}`}
					title={productName}
					timestamp={formatedDate}
					text={t("Notification.Auction.FirstBid")}
				/>
			);
		case "AUCTION_ENDED_FOR_OWNER":
			return (
				<NotificationItem
					img={AUCTION_ICON}
					link={`/product?id=${idProduct}&user=${idUser}`}
					title={productName}
					timestamp={formatedDate}
					text={t("Notification.Auction.EndedSuccess")}
				/>
			);
		case "AUCTION_ENDED_NO_BIDS":
			return (
				<NotificationItem
					img={AUCTION_ICON}
					link={`/product?id=${idProduct}&user=${idUser}`}
					title={productName}
					timestamp={formatedDate}
					text={t("Notification.Auction.EndedFail")}
				/>
			);
		case "NEW_BID":
			return (
				<NotificationItem
					img={AUCTION_ICON}
					link={`/product?id=${idProduct}&user=${idUser}`}
					title={productName}
					timestamp={formatedDate}
					text={t("Inapp.placedABid", {
						name: partnerName,
						sum: sum,
					})}
				/>
			);
		case "HIGHER_BID":
			return (
				<NotificationItem
					img={AUCTION_ICON}
					link={`/product?id=${idProduct}&user=${idPartner}`}
					timestamp={formatedDate}
					text={t("Inapp.Auction.Outbid", { name: productName })}
				/>
			);
		case "AUCTION_ENDED_LOSE":
			return (
				<NotificationItem
					img={partnerImageLink}
					link={`/product?id=${idProduct}&user=${idPartner}`}
					timestamp={formatedDate}
					text={t("Inapp.Auction.NotWinner", { name: productName })}
				/>
			);
		case "AUCTION_ENDED_WIN":
			return (
				<NotificationItem
					img={partnerImageLink}
					link={`/product?id=${idProduct}&user=${idPartner}`}
					timestamp={formatedDate}
					text={t("Inapp.Auction.Winning", { name: productName })}
				/>
			);
		case "LIKE_POST":
			return (
				<NotificationItem
					img={CHAT_ICON}
					link={`/post/${idPost}`}
					timestamp={formatedDate}
					text={likePostText(t, sum, partnerName)}
				/>
			);
		case "LIKE_COMMENT":
			return (
				<NotificationItem
					img={CHAT_ICON}
					link={`/post/${idPost}`}
					timestamp={formatedDate}
					text={likeCommentText(t, sum, partnerName)}
				/>
			);
		case "LIKE_COMMENT_ANSWER":
			return (
				<NotificationItem
					img={CHAT_ICON}
					link={`/post/${idPost}`}
					timestamp={formatedDate}
					text={likeReplyText(t, sum, partnerName)}
				/>
			);
		case "DEPOSIT_RECEIVED":
			return (
				<NotificationItem
					img={WALLET_ICON}
					link={`/wallet`}
					timestamp={formatedDate}
					text={t("Inapp.DepositReceived", { sum: sum })}
				/>
			);
		case "WITHDRAW_APPROVED":
			return (
				<NotificationItem
					img={WALLET_ICON}
					link={`/wallet`}
					timestamp={formatedDate}
					text={t("Inapp.WithdrawApproved", { sum: sum })}
				/>
			);
		case "WITHDRAW_REJECTED":
			return (
				<NotificationItem
					img={WALLET_ICON}
					link={`/wallet`}
					timestamp={formatedDate}
					text={t("Inapp.WithdrawRejected")}
				/>
			);
		case "MONEY_BACK_MESSAGE":
			return (
				<NotificationItem
					img={partnerImageLink}
					title={partnerName}
					link={`/wallet`}
					timestamp={formatedDate}
					text={t("Inapp.MoneyBackMessage")}
				/>
			);
		case "MONEY_BACK_CALL":
			return (
				<NotificationItem
					img={partnerImageLink}
					title={partnerName}
					link={`/wallet`}
					timestamp={formatedDate}
					text={t("Inapp.MoneyBackCall")}
				/>
			);
		case "BACK_ONLINE":
			return (
				<NotificationItem
					img={partnerImageLink}
					title={partnerName}
					link={`/orders?partner=${idPartner}`}
					timestamp={formatedDate}
					text={t("Inapp.BackOnline")}
				/>
			);
		case "NEW_PRODUCT":
			return (
				<NotificationItem
					img={productImage(productType)}
					title={partnerName}
					link={
						productType === "DONATION"
							? `/${idPartner}`
							: `/product?id=${idProduct}&user=${idPartner}`
					}
					timestamp={formatedDate}
					text={t("Inapp.NewProduct")}
				/>
			);
		case "EVERYTHING_SOLD":
			return (
				<NotificationItem
					img={productImage(productType)}
					title={productName}
					link={`/${idUser}`}
					timestamp={formatedDate}
					text={everythingSoldText(t, productType)}
				/>
			);
		case "PHYSICAL_MONEY_RECEIVED":
			return (
				<NotificationItem
					img={productImage(productType)}
					title={productName}
					link={`/wallet`}
					timestamp={formatedDate}
					text={t("Inapp.PhysicalMoneyReceived")}
				/>
			);
		case "PRODUCT_REMIND":
			return (
				<NotificationItem
					img={productImage(productType)}
					title={productName}
					link={`/orders?partner=${idPartner}`}
					timestamp={formatedDate}
					text={t("Inapp.ProductRemind")}
				/>
			);
		case "NEW_PRODUCT_REACTION":
			return (
				<NotificationItem
					img={partnerImageLink}
					title={productName}
					link={`/orders?partner=${idPartner}`}
					timestamp={formatedDate}
					text={t("Inapp.NewProductReaction")}
				/>
			);
		case "COLLABORATION_APPROVED":
			return (
				<NotificationItem
					img={partnerImageLink}
					title={partnerName}
					link={`/orders?partner=${idPartner}`}
					timestamp={formatedDate}
					text={t("Inapp.CollaborationApproved")}
				/>
			);
		case "COLLABORATION_REJECTED":
			return (
				<NotificationItem
					img={partnerImageLink}
					title={partnerName}
					timestamp={formatedDate}
					text={t("Inapp.CollaborationRejected")}
				/>
			);
		case "NEW_COMMENT_COMMUNITY":
			return (
				<NotificationItem
					img={CHAT_ICON}
					link={`/community/post/${idPost}`}
					timestamp={formatedDate}
					text={t("Inapp.Community.NewComment", { name: partnerName })}
				/>
			);
		case "NEW_COMMENT_ANSWER_COMMUNITY":
			return (
				<NotificationItem
					img={CHAT_ICON}
					link={`/community/post/${idPost}`}
					timestamp={formatedDate}
					text={t("Inapp.Community.NewCommentAnswer", { name: partnerName })}
				/>
			);
		case "LIKED_POST_COMMUNITY":
			return (
				<NotificationItem
					img={CHAT_ICON}
					link={`/community/post/${idPost}`}
					timestamp={formatedDate}
					text={likePostText(t, sum, partnerName)}
				/>
			);
		case "LIKE_COMMENT_COMMUNITY":
			return (
				<NotificationItem
					img={CHAT_ICON}
					link={`/community/post/${idPost}`}
					timestamp={formatedDate}
					text={likeCommentText(t, sum, partnerName)}
				/>
			);
		case "LIKE_COMMENT_ANSWER_COMMUNITY":
			return (
				<NotificationItem
					img={CHAT_ICON}
					link={`/community/post/${idPost}`}
					timestamp={formatedDate}
					text={likeReplyText(t, sum, partnerName)}
				/>
			);
		case "NEW_POST_COMMUNITY":
			return (
				<NotificationItem
					img={partnerImageLink}
					link={`/community/post/${idPost}`}
					timestamp={formatedDate}
					text={t("Inapp.Community.NewPost", { name: partnerName })}
				/>
			);
		case "PURCHASED_SUBSCRIPTION":
			return (
				<NotificationItem
					img={WALLET_ICON}
					link={`/wallet`}
					timestamp={formatedDate}
					text={
						sum > 0
							? t("Inapp.Subscription.Purchased", {
									name: partnerName,
									sum: sum,
							  })
							: t("Inapp.Subscription.Purchased.OneUser", {
									name: partnerName,
							  })
					}
				/>
			);
		case "DONATION_RECEIVED":
			return (
				<NotificationItem
					img={GIFT_ICON}
					title={partnerName}
					link={`/wallet`}
					timestamp={formatedDate}
					text={t("Inapp.ReceivedGift", { sum: sum, comment: text })}
				/>
			);
		case "EVENT_INFO":
			return (
				<NotificationItem
					img={EVENT_ICON}
					title={productName}
					timestamp={formatedDate}
					text={text}
				/>
			);
		default:
			return null;
	}
}

function likePostText(t, sum, name) {
	return sum > 0
		? t("Inapp.likedPost.MoreOneUser", {
				name: name,
				sum: sum,
		  })
		: t("Inapp.likedPost", { name: name });
}

function likeCommentText(t, sum, name) {
	return sum > 0
		? t("Inapp.likedComment.MoreOneUser", {
				name: name,
				sum: sum,
		  })
		: t("Inapp.likedComment", { name: name });
}

function likeReplyText(t, sum, name) {
	return sum > 0
		? t("Inapp.likedReply.MoreOneUser", {
				name: name,
				sum: sum,
		  })
		: t("Inapp.likedReply", { name: name });
}

const productImage = (type) => {
	switch (type) {
		case "AUTOGRAPH":
			return AUTOGRAPH_ICON;
		case "VIDEO_GREETING":
			return VG_ICON;
		case "AUTO_REPLY":
			return FILE_ICON;
		case "PHYSICAL":
			return MERCH_ICON;
		case "EVENT":
			return EVENT_ICON;
		case "COLLABORATION":
			return COLLAB_ICON;
		case "DONATION":
			return GIFT_ICON;
		default:
			return MESSAGE_ICON;
	}
};

const everythingSoldText = (t, type) => {
	switch (type) {
		case "PHYSICAL":
			return t("Inapp.EverythingMerchSold");
		default:
			return t("Inapp.EverythingSold");
	}
};

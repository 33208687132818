import { changeActiveCategory as storeActiveCategory } from "components/common/community/FeedSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function useCommunityCategories() {
	const { club } = useSelector((state) => state.profile);
	const { activeCategory } = useSelector((state) => state.communityFeed);

	const { t } = useTranslation();
	const categories = Array.isArray(club?.feedCategories)
		? [
				{
					idCategory: "ALL",
					name: t("Explore.Group.All"),
				},
				...club?.communityCategories,
		  ]
		: null;

	const changeActiveCategory = (category) => storeActiveCategory(category);

	return { categories, changeActiveCategory, activeCategory };
}

import React from "react";
import { useHistory } from "react-router-dom";
import "./css/auction-winner-modal.module.css";

// libs
import { useTranslation } from "react-i18next";

// components
import Modal from "./Modal";

export default function AuctionWinnerModal(props) {
	const {
		product: { idProduct, productName, idOwner },
		isOpen,
		closeModal,
	} = props;
	const { t } = useTranslation();
	const history = useHistory();

	return (
		<Modal
			isOpen={isOpen}
			closeModal={closeModal}
			noPadding
			maxWidth="400px"
			borderRadius="20px"
			ignoreOnClose
		>
			<div styleName="container">
				<div styleName="content">
					<div className="title-5 center mb_24">
						{t("CongratulationsAuction.Title")}
					</div>
					<div className="text-1 c_base60 center mb_24">
						{t("CongratulationsWithHighestBid.Text", {
							productName: productName,
						})}
					</div>
					<button
						className="pr-btn_prim fw"
						onClick={() =>
							history.push(
								`/auction-winner?product=${idProduct}&owner=${idOwner}`
							)
						}
					>
						{t("Proceed.Button")}
					</button>
				</div>
			</div>
		</Modal>
	);
}

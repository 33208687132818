import { isErrorHandled } from "functions";
import { openErrorSnackbar } from "redux/ducks/modal";

import i18n from "i18n";
const t = i18n.t.bind(i18n);

function handleResponseError(error, errorText = t("Forum.Common.Error.Text")) {
	if (!isErrorHandled(error)) {
		openErrorSnackbar({
			text: errorText,
		});
	}
}

export default handleResponseError;

// escape charectrs so JAVA can parse request with them
export function escapeSpecialCharacters(str) {
	return str
		.replace(/\(/g, "\\(")
		.replace(/\)/g, "\\)")
		.replace(/\[/g, "\\[")
		.replace(/\]/g, "\\]")
		.replace(/\{/g, "\\{")
		.replace(/\}/g, "\\}")
		.replace(/\*/g, "\\*");
}

import { RESET_PASS_TOKEN } from '../constants/list';

const initialState = {
  resetPassToken: ''
}

export default function resetPassToken(state = initialState, action) {
  switch (action.type) {
    case RESET_PASS_TOKEN:
      return { ...state, resetPassToken: action.payload }

    default:
      return state;
  }
}

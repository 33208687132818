import { useSelector } from "react-redux";
import { openModal } from "redux/ducks/modal";

export function useSuggestToLogin() {
	const { user } = useSelector((state) => state.user);

	const suggestToLogin = () => {
		if (!!user) return false;
		else {
			openModal("NeedToLoginModal");
			return true;
		}
	};

	return { suggestToLogin };
}

import SparkMD5 from "spark-md5";
import PBKDF2 from "pbkdf2";

export function md5(payload, type = "string") {
	if (type === "string") return SparkMD5.hash(payload);
	else if (type === "file") {
		return fileMd5(payload);
	}
}

export function pbkdf2(str) {
	return PBKDF2.pbkdf2Sync(
		str,
		"hX97NfkS5PaNv4TWrAevcbJrvrnJsMFH",
		5000,
		32,
		"sha1"
	).toString("hex");
}

function fileMd5(file) {
	return new Promise(function (resolve, reject) {
		var blobSlice =
				File.prototype.slice ||
				File.prototype.mozSlice ||
				File.prototype.webkitSlice,
			chunkSize = 2097152, // Read in chunks of 2MB
			chunks = Math.ceil(file.size / chunkSize),
			currentChunk = 0,
			spark = new SparkMD5.ArrayBuffer(),
			fileReader = new FileReader();

		fileReader.onload = async (e) => {
			spark.append(e.target.result); // Append array buffer
			currentChunk++;

			if (currentChunk < chunks) {
				loadNext();
			} else {
				resolve(spark.end());
			}
		};

		fileReader.onerror = function () {
			console.warn("error computing file md5");
			reject(new Error("error computing file md5"));
		};

		function loadNext() {
			var start = currentChunk * chunkSize,
				end = start + chunkSize >= file.size ? file.size : start + chunkSize;

			fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
		}

		loadNext();
	});
}
